import "./App.scss"

import React from "react"

import { BookingRoot } from "./modules/booking/BookingRoot"
import { CheckInRoot } from "./modules/check-in/CheckInRoot"
import { PaymentRoot } from "./modules/payment/PaymentRoot"

export const App: React.FunctionComponent = () => {
  if( document.domain.indexOf( "check-in.page" ) !== -1 || document.domain.indexOf( "mycheckin.co" ) !== -1 ) {
    return <CheckInRoot />
  } else if( document.domain.indexOf( "pay.onelocal" ) !== -1 ) {
    return <PaymentRoot />
  } else if( document.domain.indexOf( "myonlinebooking.co" ) !== -1 ) {
    return <BookingRoot />
  }

  window.location.href = "https://onelocal.com"
  return null
}
