import React from "react"
import Div100vh from "react-div-100vh"
import { animated } from "react-spring"
import styled, { css } from "styled-components"

import { FONT_WEIGHT_BOLD } from "../helpers/styles"
import { PoweredByOneLocal } from "./PoweredByOneLocal"
import { RichText } from "./RichText"
import { Spinner } from "./Spinner"

export const FullPageContainer = styled( Div100vh )`
  overflow-x: hidden;
  max-width: 100%;
  position: relative;
`
export const PageLoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const PageStepContainer = styled.div`
  display: flex;
  height: 100%;
`

const PageContainerWrapper = styled( animated.div )`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  min-height: calc( 100% - 85px );
  padding: 15px 30px;
  position: absolute;
  right: 0;
  top: 0;
  width: calc( 100% - 60px );
`

const PageContainerLoadingOverlay = styled.div`
  align-items: center;
  background: rgba( 255,255,255,0.75 );
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export interface PageContainerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isLoading?: boolean
  showPoweredBy?: boolean
  title?: string
}

export const PageContainer: React.FunctionComponent<PageContainerProps> = ( { children, isLoading, showPoweredBy, style, title, ...props } ) => {
  style = {
    ...( style || {} ),
  }

  if( showPoweredBy ) {
    style = {
      ...style,
      paddingBottom: "70px",
    }
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <PageContainerWrapper style={ style } { ...props as any }>
      { title && <PageTitle>Thank you!</PageTitle> }
      { children }
      { showPoweredBy && <PoweredByOneLocal footerMode={ true } /> }
      {
        isLoading && (
          <PageContainerLoadingOverlay>
            <Spinner />
          </PageContainerLoadingOverlay>
        )
      }
    </PageContainerWrapper>
  )
}

const PageDescriptionWrapperCss = css`
  font-size: 16px;
  margin: 0 0 45px;
  padding: 0 20px;
  text-align: center;

  strong {
    font-weight: ${ FONT_WEIGHT_BOLD };
  }
`

export const PageDescriptionWrapper = styled.div`
  ${ PageDescriptionWrapperCss }
`

export const PageDescriptionMultiLinesTextWrapper = styled( RichText )`
  ${ PageDescriptionWrapperCss }
`

export interface PageDescriptionProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text?: string
}

export const PageDescription: React.FunctionComponent<PageDescriptionProps> = ( { children, text, ...props } ) => {
  if( text ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <PageDescriptionMultiLinesTextWrapper text={ text } { ...props as any } />
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <PageDescriptionWrapper { ...props as any }>{ children }</PageDescriptionWrapper>
}

export interface PageTitleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  textAlign?: string
}

export const PageTitle = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ( { textAlign, ...props }: PageTitleProps ) => <div { ...props } />,
)<PageTitleProps>`
  font-weight: 500;
  font-size: 18px;
  margin: 10px 0 30px;
  text-align: ${ ( { textAlign }: PageTitleProps ) => textAlign || "center" };
  width: 100%;
`
