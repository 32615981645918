import React, { useCallback } from "react"
import styled from "styled-components"

import { PrimaryButton } from "../../../../components/Button"
import { PageContainer, PageDescription } from "../../../../components/Page"
import { useCheckInState } from "../../components/CheckInContext"
import { CheckInStep } from "../../types"

const StyledLogo = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  margin-bottom: 35px;
  max-height: 200px;
  max-width: 60%;
  min-height: 150px;
  width: 100%;
`

const StyledCTA = styled( PrimaryButton )`
  width: 100%;
`

export interface WelcomeProps {

}

export const WelcomePage: React.FunctionComponent<WelcomeProps> = () => {
  const { settings, setStep, visit } = useCheckInState()
  const start = useCallback( () => {
    if( visit ) {
      if( settings!.location.enabled ) {
        setStep( CheckInStep.LOCATION )
      } else {
        setStep( CheckInStep.INFORMATION )
      }
    } else {
      setStep( CheckInStep.PHONE_NUMBER )
    }

  }, [ settings, setStep, visit ] )

  if( ! settings ) {
    return null
  }

  return (
    <PageContainer
      style={ {
        bottom: "auto",
        display: "flex",
        flexDirection: "column",
        top: "auto",
      } }
      showPoweredBy={ true }
    >
      {
        settings.welcome.image_url && (
          <StyledLogo style={ { backgroundImage: `url('${ settings.welcome.image_url }')` } } />
        )
      }
      <PageDescription text={ settings.welcome.text } />
      <StyledCTA onClick={ start }>Get Started</StyledCTA>
    </PageContainer>
  )
}
