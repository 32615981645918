/**
 * Converts degrees to radians.
 *
 * @param degrees Number of degrees.
 */
export function degreesToRadians( degrees: number ) {
  return degrees * Math.PI / 180
}

export function getDistanceBetweenCoordinates( lat1: number, lng1: number, lat2: number, lng2: number ) {
  // The radius of the planet earth in meters
  const R = 6378137
  const dLat = degreesToRadians( lat2 - lat1 )
  const dLong = degreesToRadians( lng2 - lng1 )
  const a = Math.sin( dLat / 2 )
          * Math.sin( dLat / 2 )
          + Math.cos( degreesToRadians( lat1 ) )
          * Math.cos( degreesToRadians( lat1 ) )
          * Math.sin( dLong / 2 )
          * Math.sin( dLong / 2 )

  const c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) )
  const distance = R * c
  return distance
}
