import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

import { getVisitPaymentSummary } from "../../../helpers/api"
import { logError } from "../../../helpers/error"
import type { PaymentSummary } from "../types"
import { PaymentStatusType, PaymentStep } from "../types"

interface PaymentContextType {
  isLoading: boolean
  payment_id: string | null
  paymentSummary: PaymentSummary | null
  step: PaymentStep | null
  setStep: ( step: PaymentStep ) => void
  token: string | null
}

export const PaymentContext = createContext<PaymentContextType>( null! )

// eslint-disable-next-line @typescript-eslint/ban-types
export const PaymentContextProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ( props ) => {
  const [ step, setStep ] = useState<PaymentStep | null>( null )
  const [ paymentSummary, setPaymentSummary ] = useState<PaymentSummary | null>( null )
  const [ isLoading, setIsLoading ] = useState<boolean>( true )
  const location = useLocation()

  const { payment_id, token } = useMemo( () => {
    const urlParams = new URLSearchParams( location.search )
    const result = {
      payment_id: urlParams.get( "payment_id" ),
      token: urlParams.get( "token" ),
    }

    return result
  }, [ location.search ] )

  const loadPayment = async () => {
    setIsLoading( true )

    try {
      const newPaymentSummary = await getVisitPaymentSummary( payment_id!, token! )
      setPaymentSummary( newPaymentSummary )

      switch( newPaymentSummary.status.type ) {
        case PaymentStatusType.CANCELLED: {
          setStep( PaymentStep.CANCELLED )
          break
        }
        case PaymentStatusType.COMPLETED: {
          setStep( PaymentStep.COMPLETED )
          break
        }
        case PaymentStatusType.EXPIRED: {
          setStep( PaymentStep.EXPIRED )
          break
        }
        case PaymentStatusType.FAILED: {
          setStep( PaymentStep.FAILED )
          break
        }
        case PaymentStatusType.PENDING: {
          setStep( PaymentStep.PENDING )
          break
        }
        case PaymentStatusType.REFUNDED: {
          setStep( PaymentStep.COMPLETED )
          break
        }
      }
    } catch( error ) {
      logError( error )
      setStep( PaymentStep.ERROR )
    }

    setIsLoading( false )
  }

  useEffect( () => {
    if( ! payment_id || ! token ) {
      setStep( PaymentStep.ERROR )
      setIsLoading( false )
    } else {
      loadPayment()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const contextValue: PaymentContextType = {
    isLoading,
    paymentSummary,
    setStep,
    step,
    token,
    payment_id,
  }

  return <PaymentContext.Provider value={ { ...contextValue } }>{ props.children }</PaymentContext.Provider>
}

export function usePaymentState(): PaymentContextType {
  const context = useContext( PaymentContext )
  if( ! context ) {
    throw new Error( "usePaymentState must be used within the PaymentContextProvider" )
  }
  return context
}
