
import Autolinker from "autolinker"
import React from "react"

const autolinker = new Autolinker()

export interface RichTextProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children"> {
  text: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RichText: React.FC<RichTextProps> = ( { children, text, ...props } ) => {
  let htmlText = text?.replace( /(?:\r\n|\r|\n)/g, "<br />" )
  htmlText = autolinker.link( htmlText )

  return (
    <div dangerouslySetInnerHTML={ { __html: htmlText } } { ...props }/>
  )
}
