import React from "react"
import styled, { css } from "styled-components"

import { PRIMARY_COLOR, SECONDARY_COLOR } from "../helpers/styles"

export interface ButtonBaseProps {
  borderColor?: string
  borderColorHover?: string
  children: React.ReactNode
  color?: string
  colorHover?: string
  textColor?: string
  textColorHover?: string
}

const ButtonBaseCss = css<ButtonBaseProps>`
  border: none;
  border-radius: 24px;
  cursor: pointer;
  display: inline-block;
  font-family: "Gotham Rounded A", "Gotham Rounded B";
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
  outline: 0;
  padding: 11px 40px;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease, background-color 0.15s ease;
  vertical-align: middle;
  white-space: normal;

  background-color: ${ ( props: ButtonBaseProps ) => props.color || "none" };
  color: ${ ( props: ButtonBaseProps ) => props.textColor || "none" };
  border: 2px solid ${ ( props: ButtonBaseProps ) => props.borderColor || props.color };

  :focus {
    outline: 0;
  }

  :hover:not([disabled]) {
    background-color: ${ ( props: ButtonBaseProps ) => props.colorHover || "none" };
    border: 2px solid ${ ( props: ButtonBaseProps ) => props.borderColorHover || props.colorHover || "none" };
    color: ${ ( props: ButtonBaseProps ) => props.textColorHover || props.textColor || "none" };
  }

  :disabled, [disabled] {
    cursor: default;
    opacity: 0.75;
    pointer-events: none;
  }
`

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & ButtonBaseProps
export type ButtonLinkProps = React.HTMLProps<HTMLAnchorElement> & ButtonBaseProps

export const Button = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  ( { colorHover, borderColor, borderColorHover, textColor, textColorHover, ...props }: any ) => <button { ...props } />,
)<ButtonProps>`
  ${ ButtonBaseCss }
`

const createRenderButton = ( buttonProps: Omit<ButtonBaseProps, "children"> ) => (
  ( { children, ...props }: ButtonProps ) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Button { ...{ ...buttonProps, ...props } as any }>
      { children }
    </Button>
  )
)

export const PrimaryButton = createRenderButton( {
  borderColorHover: PRIMARY_COLOR,
  color: PRIMARY_COLOR,
  colorHover: "transparent",
  textColor: "white",
  textColorHover: PRIMARY_COLOR,
} )

export const SecondaryButton = createRenderButton( {
  borderColorHover: SECONDARY_COLOR,
  color: SECONDARY_COLOR,
  colorHover: "transparent",
  textColor: "white",
  textColorHover: SECONDARY_COLOR,
} )
