export type FieldAnswer = FieldAnswerMultipleChoice | FieldAnswerText

export type FieldAnswerText = string

export interface FieldAnswerMultipleChoice {
  option_ids: string[]
  other?: string
}

export interface FieldAnswers {
  [fieldId: string]: FieldAnswer
}

export enum CheckInStep {
  WELCOME = "welcome",
  PHONE_NUMBER = "phone_number",
  PHONE_NUMBER_VERIFICATION = "phone_number_verification",
  LOCATION = "location",
  INFORMATION = "information",
  COMPLETED = "completed",
  ERROR = "error",
}

export enum LocationStatus {
  NOT_DETECTED = "not_detected",
  OUT_OF_RANGE = "out_of_range",
  WITHIN_RANGE = "within_range",
}

export interface InviteSummary {
  settings: CheckInSettings
  visit: Visit
}

export interface Visit {
  id: string
  merchant_id: string
  auth_token: string
  status: {
    type: VisitStatusType
  }
}

export interface CheckInSettings {
  completed: {
    text: string
  }
  fields: CheckInSettingsField[]
  location: {
    enabled: boolean
    coordinate: {
      lat: number
      lng: number
    }
    is_required: boolean
    max_distance_meters: number
    text: string
  }
  merchant: {
    slug: string
  }
  phone_verification_enabled: boolean
  preview?: boolean
  welcome: {
    image_url: string
    text: string
  }
}

export declare namespace CheckInSettings {

}

export enum CheckInSettingsFieldType {
  MULTIPLE_CHOICE = "multiple_choice",
  TEXT = "text",
}

export interface FieldBase {
  id: string
  is_required: boolean
  label: string
  type: CheckInSettingsFieldType
}

export interface CheckInSettingsFieldText extends FieldBase {
  type: CheckInSettingsFieldType.TEXT
  input_type: CheckInSettingsFieldTextInputType
}

export enum CheckInSettingsFieldTextInputType {
  EMAIL = "email",
  NUMBER = "number",
  PHONE_NUMBER = "phone_number",
  TEXT = "text",
}

export interface CheckInSettingsFieldMultipleChoice extends FieldBase {
  type: CheckInSettingsFieldType.MULTIPLE_CHOICE
  options: CheckInSettingsFieldMultipleChoiceOption[]
  include_other_choice: boolean
  allow_multiple_answers: boolean
}

export interface CheckInSettingsFieldMultipleChoiceOption {
  id: string
  label: string
}

export const FIELD_OTHER_OPTION_ID = "other"

export type CheckInSettingsField = CheckInSettingsFieldMultipleChoice | CheckInSettingsFieldText

export enum VisitStatusType {
  CANCELLED = "cancelled",
  CHECKED_IN = "checked_in",
  COMPLETED = "completed",
  INVITED = "invited",
  NOTIFIED = "notified",
  STARTED = "started",
}
