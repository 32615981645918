import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"

import { PrimaryButton } from "../../../../components/Button"
import { PageContainer } from "../../../../components/Page"
import { sendPhoneNumberVerificationCode } from "../../../../helpers/api"
import { ERROR_COLOR, FONT_WEIGHT_MEDIUM } from "../../../../helpers/styles"
import { useLayoutEffectTimeout } from "../../../../hooks/useLayoutEffectTimeout"
import { useCheckInState } from "../../components/CheckInContext"
import { CheckInStep } from "../../types"

const StyledPageContainer = styled( PageContainer )`
  align-items: stretch;
  justify-content: flex-start;
  padding-top: 50px;
`

const StyledLabel = styled.label`
  display: block;
  font-size: 18px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  margin-bottom: 10px;
`

const StyledLabelRequired = styled.span`
  color: red;
  margin-left: 5px;
`

const StyledCTA = styled( PrimaryButton )`
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const StyledFormControl = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  /* This ensure the UI doesn't jump when a error is display or not */
  min-height: 95px;
`

interface StyledInputProps {
  hasError?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
  border: none;
  border-bottom: 2px solid ${ ( props ) => props.hasError ? ERROR_COLOR : "#ccc" };
  border-radius: 0;
  display: block;
  font-size: 20px;
  line-height: 30px;
  position: relative;

  &:focus {
    ${ ( props ) => props.hasError ? "" : "border-bottom-color: black" };
    outline: 0;
  }
`

const StyledError = styled.div`
  color: ${ ERROR_COLOR };
  font-size: 16px;
  margin-top: 5px;
`

export interface PhoneNumberPageProps {
}

export const PhoneNumberPage: React.FunctionComponent<PhoneNumberPageProps> = () => {
  const { model, setModel, setStep, settings } = useCheckInState()
  const inputRef = useRef<HTMLInputElement | null>( null )
  const { formState, handleSubmit, register } = useForm( {
    defaultValues: {
      phoneNumber: model.phoneNumber,
    },
    mode: "onChange",
  } )
  const [ errorMessage, setErrorMessage ] = useState<string | null>( null )
  const [ isLoading, setIsLoading ] = useState<boolean>( false )

  const submitHandler = handleSubmit( ( { phoneNumber } ) => {
    if( ! formState.isValid || isLoading ) {
      return
    }

    if( phoneNumber == null || phoneNumber.length === 0 ) {
      setErrorMessage( "This is a mandatory field" )
      return
    }

    setErrorMessage( null )
    setIsLoading( true )

    sendPhoneNumberVerificationCode( settings!.merchant.slug, phoneNumber )
      .then(
        () => {
          setModel( { ...model, phoneNumber } )

          if( settings!.phone_verification_enabled ) {
            setStep( CheckInStep.PHONE_NUMBER_VERIFICATION )
          } else if( settings!.location.enabled ) {
            setStep( CheckInStep.LOCATION )
          } else {
            setStep( CheckInStep.INFORMATION )
          }
        },
        ( error: Error ) => {
          setErrorMessage( error.message )
          setIsLoading( false )
        },
      )
  } )

  const nextStep = handleSubmit( ( { phoneNumber } ) => {
    if( phoneNumber ) {
      setModel( { ...model, phoneNumber } )
    }

    if( settings!.phone_verification_enabled ) {
      setStep( CheckInStep.PHONE_NUMBER_VERIFICATION )
    } else if( settings!.location.enabled ) {
      setStep( CheckInStep.LOCATION )
    } else {
      setStep( CheckInStep.INFORMATION )
    }
  } )

  useLayoutEffectTimeout( () => inputRef?.current?.focus(), 500 )

  if( ! settings ) {
    return null
  }

  return (
    <StyledPageContainer isLoading={ isLoading }>
      <StyledForm onSubmit={ settings.preview ? nextStep : submitHandler } noValidate={ true }>
        <StyledFormControl>
          <StyledLabel>Enter your mobile number <StyledLabelRequired>*</StyledLabelRequired></StyledLabel>
          <StyledInput
            type="text"
            name="phoneNumber"

            ref={ ( e ) => {
              register( e )
              inputRef.current = e
            } }
            hasError={ ( errorMessage != null ) }
            inputMode="tel"
            autoComplete="tel"
            placeholder="647-123-4567"
          />
          { errorMessage && <StyledError>{ errorMessage }</StyledError> }
        </StyledFormControl>
        <StyledCTA type="submit">Next</StyledCTA>
      </StyledForm>

    </StyledPageContainer>
  )
}
