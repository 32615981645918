import axios from "axios"

import type { LocalVisits } from "ol-shared"

import type { BookingSettings, CalendarEvent, CreateModel } from "../modules/booking/types"
import type { CheckInSettings, FieldAnswers, InviteSummary, LocationStatus } from "../modules/check-in/types"
import type { PaymentSummary } from "../modules/payment/types"
import { HttpError } from "./error"

export interface ApiRequestParams {
  [key: string]: string | number | boolean | undefined | null
}

export interface HttpRequestOption {
  data?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  method: HttpMethod
  params?: ApiRequestParams
  url: string
}

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

const DEFAULT_ERROR_MESSAGE = "Unknown error"

const axiosInstance = axios.create()
axiosInstance.defaults.headers.common[ "Accept" ] = "application/json"

export async function sendHttpRequest<T>( options: HttpRequestOption ) {
  const axiosOptions = {
    ...options,
  }

  const response = await axiosInstance( axiosOptions )
    .catch(
      ( error ) => {
        if( error.response ) {
          const { data } = error.response
          const message = data.message || DEFAULT_ERROR_MESSAGE
          throw new HttpError( message, {
            data,
            errorCode: data.errors?.length > 0 ? data.errors[ 0 ].code : null,
            statusCode: error.response.status,
          } )
        }

        throw new Error( DEFAULT_ERROR_MESSAGE )
      },
    )

  return response.data as T
}

export async function getVisitBookingsServiceAssignees( slug: string, groupId: string, typeId: string ): Promise<LocalVisits.Types.Assignee[]> {
  return sendHttpRequest<LocalVisits.Types.Assignee[]>( {
    method: HttpMethod.GET,
    url: `/visits-bookings-assignees/${ slug }/groups/${ groupId }/types/${ typeId }`,
  } )
}

export async function getVisitBookingsServiceSchedule( slug: string, groupId: string, typeId: string, options: {
  assigneeId?: string | null
  rangeEndDate?: string
  rangeStartDate?: string
} ): Promise<LocalVisits.Types.Booking.PublicServiceSchedule> {
  const url = `/visits-bookings-service-schedule/${ slug }/groups/${ groupId }/types/${ typeId }`
  const params: ApiRequestParams = {}

  if( options.assigneeId ) {
    params[ "assignee_id" ] = options.assigneeId
  }

  if( options.rangeEndDate ) {
    params[ "range_end_date" ] = options.rangeEndDate
  }

  if( options.rangeStartDate ) {
    params[ "range_start_date" ] = options.rangeStartDate
  }

  return sendHttpRequest<LocalVisits.Types.Booking.PublicServiceSchedule>( {
    method: HttpMethod.GET,
    url,
    params,
  } )
}

export async function getVisitBookingsSettings( slug: string ): Promise<BookingSettings> {
  return sendHttpRequest<BookingSettings>( {
    method: HttpMethod.GET,
    url: `/visits-bookings-settings/${ slug }`,
  } )
}

export async function createBooking( slug: string, model: CreateModel ): Promise<CalendarEvent> {
  return sendHttpRequest<CalendarEvent>( {
    method: HttpMethod.POST,
    url: `/visits-bookings/${ slug }/bookings`,
    data: model,
  } )
}

export async function getVisitCheckInSummary( visitId: string, token: string ): Promise<InviteSummary> {
  return sendHttpRequest<InviteSummary>( {
    method: HttpMethod.GET,
    url: `/visits/${ visitId }/check-in-summary?token=${ token }`,
  } )
}

export async function getVisitCheckInSettings( slug: string ): Promise<CheckInSettings> {
  return sendHttpRequest<CheckInSettings>( {
    method: HttpMethod.GET,
    url: `/visits-check-in-settings/${ slug }`,
  } )
}

export async function getVisitPaymentSummary( paymentId: string, token: string ): Promise<PaymentSummary> {
  return sendHttpRequest<PaymentSummary>( {
    method: HttpMethod.GET,
    url: `/visits_payments/${ paymentId }/summary?token=${ token }`,
  } )
}

export interface VisitCheckInModel {
  answers: FieldAnswers
  location_status?: LocationStatus
}

export async function sendVisitCheckIn( visitId: string, token: string, model: VisitCheckInModel ): Promise<void> {
  return sendHttpRequest<void>( {
    method: HttpMethod.POST,
    url: `/visits/${ visitId }/check-in?token=${ token }`,
    data: model,
  } )
}

export async function sendPhoneNumberVerificationCode( slug: string, phoneNumber: string ): Promise<void> {
  return sendHttpRequest<void>( {
    method: HttpMethod.POST,
    url: `/visits-check-in/${ slug }/send-verification-code`,
    data: {
      phone_number: phoneNumber,
    },
  } )
}

export async function verifyPhoneNumberVerificationCode( slug: string, phoneNumber: string, code: string ): Promise<void> {
  return sendHttpRequest<void>( {
    method: HttpMethod.POST,
    url: `/visits-check-in/${ slug }/verify-code`,
    data: {
      code,
      phone_number: phoneNumber,
    },
  } )
}

export interface CreateVisitModel {
  answers: FieldAnswers
  location_status?: LocationStatus
  phoneNumber: string
  phoneNumberCode: string
}

export async function createVisit( slug: string, model: CreateVisitModel ): Promise<void> {
  const { location_status } = model
  return sendHttpRequest<void>( {
    method: HttpMethod.POST,
    url: `/visits-check-in/${ slug }/visits`,
    data: {
      answers: model.answers,
      ...( location_status && { location_status } ),
      phone_number: model.phoneNumber,
      phone_number_verification_code: model.phoneNumberCode,
    },
  } )
}
