import React from "react"

import { PrimaryButton } from "./Button"
import { PageContainer, PageDescription, PageTitle } from "./Page"
import { PoweredByOneLocal } from "./PoweredByOneLocal"

export interface CompletedProps {

}

export const ErrorPage: React.FunctionComponent = () => {
  const reload = () => {
    window.location.reload()
  }

  return (
    <PageContainer>
      <PageTitle>Something went wrong</PageTitle>
      <PageDescription>There was a problem loading data.</PageDescription>
      <PrimaryButton onClick={ reload }>Refresh</PrimaryButton>
      <PoweredByOneLocal footerMode={ true }/>
    </PageContainer>
  )
}
