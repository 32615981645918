import * as Sentry from "@sentry/browser"

import { config, ConfigEnv } from "./config"

Sentry.init( {
  dsn: "https://766c5c5de94b45fe85169073c6891463@o10578.ingest.sentry.io/5226965",
  environment: config.env,
  enabled: config.env !== ConfigEnv.DEV,
  release: config.gataweb_version,
} )

export interface ErrorOptions {
  /** Added to extra on error in Sentry  */
  extras?: { [ key: string ]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
}

export enum ErrorType {
  HTTP = "http",
}

export class ErrorBase extends Error {
  public options: ErrorOptions
  public type: ErrorType

  constructor( type: ErrorType, message: string, options?: ErrorOptions ) {
    super( message )
    this.type = type
    this.options = options || {}
  }
}

export interface HttpErrorOptions extends ErrorOptions {
  data: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errorCode?: string
  statusCode: number
}

export class HttpError extends ErrorBase {
  public data: any // eslint-disable-line @typescript-eslint/no-explicit-any
  public errorCode: string
  public statusCode: number

  constructor( message: string, options: HttpErrorOptions ) {
    super( ErrorType.HTTP, message, options )
    this.data = options.data
    this.errorCode = options.data
    this.statusCode = options.data
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logError = ( error: Error, options?: { extras?: { [ key: string ]: any } } ) => {

  Sentry.withScope( ( scope ) => {
    let extras = {}

    if( error instanceof ErrorBase && error.options?.extras ) {
      extras = {
        ...error.options.extras,
      }
    }

    if( options?.extras ) {
      extras = {
        ...options.extras,
      }
    }

    scope.setExtras( extras )

    Sentry.captureException( error )
  } )
}
