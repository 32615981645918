import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import type { FieldErrors, Resolver } from "react-hook-form"
import { useForm } from "react-hook-form"
import { animated } from "react-spring"
import styled from "styled-components"

import { PrimaryButton } from "../../../../components/Button"
import { ERROR_COLOR } from "../../../../helpers/styles"
import { isEmail, isInteger } from "../../../../helpers/validation"
import { useDisplayAnimation } from "../../../../hooks/useDisplayAnimation"
import { useCheckInState } from "../../components/CheckInContext"
import type { CheckInSettings, CheckInSettingsFieldText, FieldAnswerText } from "../../types"
import { CheckInSettingsFieldTextInputType } from "../../types"
import { FieldLabel } from "./FieldLabel"

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const StyledFormControl = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  /* This ensure the UI doesn't jump when a error is display or not */
  min-height: 95px;
`

interface StyledInputProps {
  hasError?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
  border: none;
  border-bottom: 2px solid ${ ( props ) => props.hasError ? ERROR_COLOR : "#ccc" };
  border-radius: 0;
  display: block;
  font-size: 20px;
  line-height: 30px;
  position: relative;

  &:focus {
    ${ ( props ) => props.hasError ? "" : "border-bottom-color: black" };
    outline: 0;
  }
`

const StyledError = styled.div`
  color: ${ ERROR_COLOR };
  font-size: 16px;
  margin-top: 5px;
`

const StyledNextButton = styled( PrimaryButton )`
  width: 100%;
`

export interface InformationFieldTextProps {
  fieldSettings: CheckInSettingsFieldText
  initialAnswer: FieldAnswerText | null
  onCompleted( answer: FieldAnswerText | null ): void
  onIsFocusModeChanged( isFocusMode: boolean ): void
  onValidityChanged( isValid: boolean ): void
}

export interface InformationFieldTextRef {
  submit(): void
}

interface FormValues {
  answer: string
}

const validationResolver: Resolver<FormValues, { fieldSettings: CheckInSettingsFieldText, settings: CheckInSettings | null }> = ( values, validationContext ) => {
  const { fieldSettings, settings } = validationContext!
  const errors: FieldErrors<FormValues> = {}

  if( values.answer == null || values.answer.length === 0 ) {
    if( fieldSettings.is_required && ! settings!.preview ) {
      errors.answer = {
        type: "required",
        message: "This is a mandatory field",
      }
    }
  } else {
    switch( fieldSettings.input_type ) {
      case CheckInSettingsFieldTextInputType.EMAIL:
        if( ! isEmail( values.answer ) ) {
          errors.answer = {
            type: "email",
            message: "Email is invalid",
          }
        }
        break
      case CheckInSettingsFieldTextInputType.NUMBER:
        if( ! isInteger( values.answer ) ) {
          errors.answer = {
            type: "integer",
            message: "Number is invalid",
          }
        }
        break
    }
  }

  return {
    values: errors.answer ? {} : values,
    errors,
  }
}

export const InformationFieldText = React.forwardRef<InformationFieldTextRef, InformationFieldTextProps>( ( { fieldSettings, initialAnswer, onCompleted, onIsFocusModeChanged, onValidityChanged }, ref ) => {
  const { settings } = useCheckInState()
  const fields = settings!.fields || []
  const textFieldRef = useRef<HTMLInputElement | null>( null )
  const formRef = useRef<HTMLFormElement>( null )
  const [ isValid, setIsValid ] = useState<boolean>( false )
  const [ isFocusMode, setIsFocusMode ] = useState<boolean>( false )
  const { errors, formState, handleSubmit, register, setValue, trigger } = useForm( {
    mode: "onChange",
    resolver: validationResolver,
    context: { fieldSettings, settings },
  } )

  const nextButtonContainerSpring = useDisplayAnimation( isFocusMode, isFocusMode ? { transform: "scale(1, 1)" } : { transform: "scale(0.85, 0.85)" } )

  useImperativeHandle( ref, () => ( {
    submit: () => {
      // Cancellable need to explicitly set to true to allow preventDefault in firefox otherwise form resets
      if( formRef.current ) {
        formRef.current.dispatchEvent( new Event( "submit", { bubbles: true, cancelable: true } ) )
      }
    },
  } ) )

  useEffect( () => {
    setValue( "answer", initialAnswer || "" )
    if( initialAnswer ) {
      trigger()
    }

  }, [ initialAnswer, setValue, trigger ] )

  useEffect( () => {
    updateValidity()
  } )

  const updateValidity = useCallback( () => {
    setIsValid( formState.isValid )

    if( formState.isValid !== isValid && onValidityChanged ) {
      onValidityChanged( formState.isValid )
    }

    return formState.isValid
  }, [ formState.isValid, isValid, onValidityChanged, setIsValid ] )

  const submitHandler = handleSubmit( ( { answer } ) => {
    if( ! formState.isValid ) {
      return
    }

    if( onCompleted ) {
      onCompleted( answer )
    }
  } )

  const answerTextOnFocusHandler = () => {
    // Because onBlur is delay we also need to delay the focus otherwise
    // the field won't be re-focus in case of validation error.
    setTimeout( () => {
      setIsFocusMode( true )
      onIsFocusModeChanged( true )
    }, 100 )
  }

  const answerTextOnBlurHandler = () => {
    // Delay the event propagation otherwise it will prevent the user to click
    // on the submit button
    setTimeout( () => {
      setIsFocusMode( false )
      onIsFocusModeChanged( false )
    }, 100 )
  }

  const isLastField = fields.indexOf( fieldSettings ) + 1 >= fields.length

  const inputType = useMemo( () => {
    switch( fieldSettings.input_type ) {
      case CheckInSettingsFieldTextInputType.EMAIL:
        return "email"
      case CheckInSettingsFieldTextInputType.NUMBER:
        return "number"
      case CheckInSettingsFieldTextInputType.PHONE_NUMBER:
        return "tel"
      default:
        return "text"
    }
  }, [ fieldSettings ] )

  const inputMode = useMemo( () => {
    switch( fieldSettings.input_type ) {
      case CheckInSettingsFieldTextInputType.EMAIL:
        return "email"
      case CheckInSettingsFieldTextInputType.NUMBER:
        return "numeric"
      case CheckInSettingsFieldTextInputType.PHONE_NUMBER:
        return "tel"
      default:
        return "text"
    }
  }, [ fieldSettings ] )

  const inputAutocomplete = useMemo( () => {
    switch( fieldSettings.input_type ) {
      case CheckInSettingsFieldTextInputType.EMAIL:
        return "email"
      case CheckInSettingsFieldTextInputType.PHONE_NUMBER:
        return "tel"
      default:
        return "off"
    }
  }, [ fieldSettings ] )

  const inputPlaceholder = useMemo( () => {
    switch( fieldSettings.input_type ) {
      case CheckInSettingsFieldTextInputType.EMAIL:
        return "somebody@email.com"
      case CheckInSettingsFieldTextInputType.PHONE_NUMBER:
        return "647-123-4567"
      default:
        return "Type your answer here..."
    }
  }, [ fieldSettings ] )

  return (
    <StyledForm onSubmit={ submitHandler } ref={ formRef } noValidate={ true }>
      <StyledFormControl>
        <FieldLabel fieldSettings={ fieldSettings } />
        <StyledInput
          type={ inputType }
          name="answer"
          ref={ ( instance ) => {
            if( instance ) {
              register( instance, { required: true } )
            }
            textFieldRef.current = instance
          } }
          hasError={ ( errors.answer != null ) }
          onFocus={ answerTextOnFocusHandler }
          onBlur={ answerTextOnBlurHandler }
          inputMode={ inputMode }
          autoComplete={ inputAutocomplete }
          placeholder={ inputPlaceholder }
        />
        { errors.answer && <StyledError>{ errors.answer.message }</StyledError> }
      </StyledFormControl>
      <animated.div style={ nextButtonContainerSpring }>
        <StyledNextButton type="submit" disabled={ ! isValid }>{ isLastField ? "Submit" : "Next" }</StyledNextButton>
      </animated.div>
    </StyledForm>
  )
} )
