import React from "react"

import { PRIMARY_COLOR } from "../helpers/styles"
import { SpinnerIcon } from "./Icon"

export interface SpinnerProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

export const Spinner: React.FunctionComponent<SpinnerProps> = ( { size, ...props } ) => {
  size = size || 75

  return (
    <SpinnerIcon { ...props } style={ { stroke: PRIMARY_COLOR, height: `${ size }px`, width: `${ size }px` } }/>
  )
}
