import React, { useCallback } from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"

import { PRIMARY_COLOR } from "../../../../helpers/styles"

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
`

const StyledProgressBarContainer = styled.div`
  background-color: #E0E0E0;
  border-radius: 2px;
  display: flex;
  flex: 1;
  height: 5px;
`

const StyledIconBackIcon = styled.svg`
  padding-right: 15px;
`

const StyledProgressBarBar = styled( animated.div )`
  background-color: ${ PRIMARY_COLOR };
  border-radius: 2px;
`

export interface InformationHeaderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  progress: number
  showBackButton: boolean
  onBackClicked(): void
}

export const InformationHeader: React.FunctionComponent<InformationHeaderProps> = ( { onBackClicked, progress, showBackButton, ...props } ) => {
  const springProps = useSpring( { width: `${ progress }%` } )

  const onBackClickHandler = useCallback( () => {
    onBackClicked()
  }, [ onBackClicked ] )

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <StyledContainer { ...props as any }>
      {
        showBackButton && (
          <StyledIconBackIcon onClick={ onBackClickHandler } width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7085 9.04162H5.41266L10.7064 2.68329C10.9539 2.38547 11.073 2.00152 11.0375 1.6159C11.0019 1.23028 10.8146 0.874573 10.5168 0.627038C10.219 0.379502 9.83506 0.260412 9.44944 0.295966C9.06382 0.33152 8.70811 0.518805 8.46058 0.816621L1.16891 9.56662C1.11986 9.63622 1.07599 9.70934 1.03766 9.78537C1.03766 9.85829 1.03766 9.90204 0.93558 9.97496C0.869479 10.1422 0.834869 10.3202 0.833496 10.5C0.834869 10.6798 0.869479 10.8577 0.93558 11.025C0.93558 11.0979 0.935579 11.1416 1.03766 11.2145C1.07599 11.2906 1.11986 11.3637 1.16891 11.4333L8.46058 20.1833C8.5977 20.3479 8.7694 20.4803 8.96348 20.571C9.15756 20.6618 9.36925 20.7086 9.5835 20.7083C9.92424 20.709 10.2545 20.5903 10.5168 20.3729C10.6645 20.2504 10.7866 20.1001 10.876 19.9304C10.9655 19.7607 11.0206 19.5751 11.0382 19.3841C11.0558 19.1931 11.0356 19.0005 10.9787 18.8173C10.9217 18.6341 10.8292 18.464 10.7064 18.3166L5.41266 11.9583H22.7085C23.0953 11.9583 23.4662 11.8046 23.7397 11.5312C24.0132 11.2577 24.1668 10.8867 24.1668 10.5C24.1668 10.1132 24.0132 9.74225 23.7397 9.46876C23.4662 9.19527 23.0953 9.04162 22.7085 9.04162Z" fill="#333333"/>
          </StyledIconBackIcon>
        )
      }

      <StyledProgressBarContainer>
        <StyledProgressBarBar style={ springProps }/>
      </StyledProgressBarContainer>
    </StyledContainer>
  )
}
