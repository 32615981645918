import React, { useCallback } from "react"
import { animated, useTransition } from "react-spring"
import { useMountedState, usePrevious } from "react-use"

import { ErrorPage } from "../../../components/ErrorPage"
import { FullPageContainer, PageLoadingContainer, PageStepContainer } from "../../../components/Page"
import { Spinner } from "../../../components/Spinner"
import { CancelledPage } from "../pages/CancelledPage/CancelledPage"
import { CompletedPage } from "../pages/CompletedPage/CompletedPage"
import { ExpiredPage } from "../pages/ExpiredPage/ExpiredPage"
import { PendingPage } from "../pages/PendingPage/PendingPage"
import { PaymentStep } from "../types"
import { usePaymentState } from "./PaymentContext"

export const PaymentContainer: React.FunctionComponent = () => {
  const { isLoading, step } = usePaymentState()
  const isMounted = useMountedState()
  const previousStep = usePrevious( step )

  const transitions = useTransition(
    step,
    {
      enter: { opacity: 1, transform: "translate(0%,0)" },
      from: { opacity: 0, transform: "translate(100%,0)" },
      immediate: ! isMounted() || ! previousStep,
      key: step,
      leave: { opacity: 0, transform: "translate(-50%,0)" },
    },
  )

  const renderStep = useCallback( ( currentStep: PaymentStep ) => {
    switch( currentStep ) {
      case PaymentStep.CANCELLED:
        return <CancelledPage />
      case PaymentStep.COMPLETED:
        return <CompletedPage />
      case PaymentStep.ERROR:
        return <ErrorPage />
      case PaymentStep.EXPIRED:
        return <ExpiredPage />
      case PaymentStep.PENDING:
        return <PendingPage />
      default:
    }
  }, [] )

  return (
    <FullPageContainer>
      {
        isLoading
          ? (
            <PageLoadingContainer>
              <Spinner />
            </PageLoadingContainer>
          )
          : (
            transitions( ( style, item ) => {
              return (
                <animated.div
                  key={ item }
                  style={ {
                    ...style,
                    background: "white",
                    flex: 1,
                    height: "100%",
                    position: "absolute",
                    width: "100%",
                  } }
                >
                  <PageStepContainer>
                    { renderStep( item! ) }
                  </PageStepContainer>
                </animated.div>
              )
            } )
          )
      }
    </FullPageContainer>
  )
}
