import styled from "styled-components"

import { LARGE_SCREEN_MEDIA_QUERY } from "../../../helpers/responsive"
import { FONT_WEIGHT_MEDIUM, LIGHT_COLOR } from "../../../helpers/styles"

export const PaymentPageContactDescription = styled.div`
  color: ${ LIGHT_COLOR };
  font-size: 12px;
  margin: 0 30px 30px 30px;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 18px;
  }
`

export const PaymentPageDescription = styled.div`
  font-size: 18px;
  margin-bottom: 25px;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 22px;
    margin-bottom: 47px;
  }
`

export const PaymentPageHeadline = styled.div`
  font-size: 20px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  margin-bottom: 30px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 30px;
  }
`

export const PaymentPageTitle = styled.h1`
  font-size: 20px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  text-align: center;
  margin-bottom: 40px;
  margin-top: 28px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    margin-top: 47px;
  }
`

export const PaymentPagePayeeName = styled.span`
  font-weight: ${ FONT_WEIGHT_MEDIUM };
`
