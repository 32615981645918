import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import moment from "moment"
import type { CSSProperties } from "react"
import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { pick } from "underscore"

import type { LocalVisits } from "ol-shared"

import { createBooking } from "../../../../helpers/api"
import { logError } from "../../../../helpers/error"
import { LARGE_SCREEN_MEDIA_QUERY, MEDIUM_SCREEN_MAX_WIDTH } from "../../../../helpers/responsive"
import { BORDER_COLOR, TEXT_DARK_COLOR } from "../../../../helpers/styles"
import { isEmail, isPhone } from "../../../../helpers/validation"
import { BookingStepPageContainer, BookingStepPageMainContainer, BookingStepPageMainContentContainer } from "../../components/BookingContainer"
import { useBookingState } from "../../components/BookingContext"
import { BookingFooter } from "../../components/BookingFooter"
import { Select } from "../../components/Select"
import type {
  BookingFieldBoolean,
  CreateModel } from "../../types"
import {
  BookingFieldTextInputType,
  BookingFieldType,
  BookingStep,
  CalendarEventStatusType,
  ScheduleType,
} from "../../types"

export interface CustomerProps {
}

const CustomerFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const CustomerFieldContainer = styled.div`
  display: flex;
  width: 100%;

  @media ( max-width: ${ MEDIUM_SCREEN_MAX_WIDTH }px ) {
    & + & {
      padding-top: 30px;
    }
  }

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    box-sizing: border-box;
    margin: 0;
    padding: 0px 20px 30px 20px;
    width: 50%;

    &.address,
    &.boolean,
    &.full-width,
    &.multiple_choice,
    &.name,
    &.note,
    &.number {
      width: 100%;
    }
  }
`

const StyledForm = styled.form`
  display: flex;
  height: 100%;
  width: 100%;
`

const StyledFormControlLabel = withStyles( {
  root: {
    paddingRight: "10px",
    "&.checkbox-invalid": {
      backgroundColor: "rgb( 221, 44, 0, 0.1 )",
    },
  },
  label: {
    color: TEXT_DARK_COLOR,
    fontSize: "14px",
  },
} )( FormControlLabel )

const StyledCheckbox = withStyles( {
  root: {
    "&.checkbox-invalid": {
      color: "#dd2c00",
    },
  },
} )( Checkbox )

const StyledLabel = styled.div`
  a {
    color: ${ props => props.theme.hyperlink };
    font-weight: 500;
  }
`

const StyledTextField = styled( TextField )`
  .MuiInput-underline:before {
    border-bottom: 1px solid ${ BORDER_COLOR };
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -moz-appearance:textfield;
    -webkit-appearance: none;
    margin: 0;
  }
`

export const CustomerPage: React.FunctionComponent<CustomerProps> = () => {
  const { isPreview, model, settings, setCalendarEvent, setErrorMessage, setModel, setStep } = useBookingState()
  const getValuesRef = useRef<any>( null ) // eslint-disable-line @typescript-eslint/no-explicit-any
  const [ isProcessing, setIsProcessing ] = useState<boolean>( false )

  // This needs to be done before useForm otherwise on changing to a prev it won't store partial values
  useEffect( () => () => {
    if( getValuesRef?.current ) {
      const updatedModel = {
        ...model,
        answers: getValuesRef.current(),
      }
      setModel( updatedModel )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const { formState, getValues, handleSubmit, errors, register, setValue } = useForm( {
    mode: "onSubmit",
    defaultValues: model.answers,
  } )

  useEffect( () => {
    getValuesRef.current = getValues
  } )

  const submitHandler = handleSubmit( ( data ) => {
    if( isProcessing ) {
      return
    }

    setIsProcessing( true )
    const updatedModel = {
      ...model,
      answers: data,
    }
    setModel( updatedModel )
    const createModel = {
      booking: pick( updatedModel, "answers", "service" ),
      schedule: {
        type: updatedModel.schedule!.type,
      },
    } as CreateModel

    if( updatedModel.schedule!.date ) {
      const date = moment( updatedModel.schedule!.date ).format( "YYYY-MM-DD" )

      if( updatedModel.schedule!.type === ScheduleType.ANY ) {
        createModel.schedule.date = date
      } else if( updatedModel.schedule!.type === ScheduleType.TIME ) {
        createModel.schedule = {
          ...createModel.schedule,
          date,
          end: moment( updatedModel.schedule!.end, "hh:mm a" ).format( "HH:mm" ),
          start: moment( updatedModel.schedule!.start, "hh:mm a" ).format( "HH:mm" ),
        }
      }
    }

    if( updatedModel.schedule!.time_slot_id ) {
      createModel.booking.service.time_slot_id = updatedModel.schedule!.time_slot_id
    }

    if( model.assignee?.id ) {
      createModel.assignee_id = model.assignee.id
    }

    if( model.utm_source ) {
      createModel.utm_source = model.utm_source
    }

    setErrorMessage( null )

    if( isPreview ) {
      const calendarEventStatusType = settings?.pending.enabled ? CalendarEventStatusType.PENDING : CalendarEventStatusType.CONFIRMED
      setCalendarEvent( {
        id: "",
        status: {
          at: "",
          type: calendarEventStatusType,
        },
      } )
      setStep( BookingStep.CREATED )
      setIsProcessing( false )
      return
    }

    createBooking( settings!.merchant.slug, createModel )
      .then(
        ( calenderEvent ) => {
          setCalendarEvent( calenderEvent )
          setStep( BookingStep.CREATED )
          if( typeof ga !== "undefined" ) {
            const tracker = ga.getAll()[ 0 ]
            if( tracker ) {
              tracker.send( "event", "booking", "completed booking" )
            }
          }
        },
        ( error ) => {
          logError( error )
          setErrorMessage( error.message )
          setIsProcessing( false )
        },
      )
  } )

  if( ! settings ) {
    return null
  }

  const getBooleanFieldValidation = ( booleanField: BookingFieldBoolean ) => {
    if( booleanField.is_required ) {
      return ( value: boolean ) => value === true
    }

    return () => true
  }

  const getInputMode = ( inputType: BookingFieldTextInputType ) => {
    switch( inputType ) {
      case BookingFieldTextInputType.EMAIL:
        return "email"
      case BookingFieldTextInputType.NUMBER:
        return "numeric"
      case BookingFieldTextInputType.PHONE_NUMBER:
        return "tel"
    }

    return "text"
  }

  const getTextFieldType = ( inputType: BookingFieldTextInputType ) => {
    switch( inputType ) {
      case BookingFieldTextInputType.EMAIL:
        return "email"
      case BookingFieldTextInputType.NUMBER:
        return "number"
      case BookingFieldTextInputType.PHONE_NUMBER:
        return "tel"
    }

    return "text"
  }

  const getTextFieldValidation = ( value: string, inputType: BookingFieldTextInputType ) => {
    if( ! value.length ) {
      return
    }

    switch( inputType ) {
      case BookingFieldTextInputType.EMAIL:
        return isEmail( value ) || "Please enter a valid email address"
      case BookingFieldTextInputType.NUMBER:
        return ! Number.isNaN( Number( value ) ) || "Please enter a valid number"
      case BookingFieldTextInputType.PHONE_NUMBER:
        return isPhone( value ) || "Please enter a valid phone number"
    }
  }

  const onSelectFieldUpdated = ( name: string, value: LocalVisits.Types.Booking.AnswerMultipleChoice ) => {
    setValue( name, value )
  }

  const getDefaultCheckedValue = ( field: BookingFieldBoolean ) => {
    if( model.answers?.[ field.id ] != null ) {
      return model.answers[ field.id ] as boolean
    }

    return field.default_value
  }

  return (
    <StyledForm noValidate={ true } autoComplete="off" onSubmit={ submitHandler }>
      <BookingStepPageContainer>
        <BookingStepPageMainContainer>
          <BookingStepPageMainContentContainer>
            <CustomerFieldsContainer>
              {
                settings.fields.map( ( field ) => {
                  switch( field.type ) {
                    case BookingFieldType.BOOLEAN:
                      return (
                        <CustomerFieldContainer key={ field.id } className={ field.type }>
                          <StyledFormControlLabel
                            name={ field.id }
                            className={ formState.errors[ field.id ] ? "checkbox-invalid" : "" }
                            control={ (
                              <StyledCheckbox
                                color="primary"
                                defaultChecked={ getDefaultCheckedValue( field ) }
                                className={ errors[ field.id ] ? "checkbox-invalid" : "" }
                              />
                            ) }
                            label={
                              <StyledLabel dangerouslySetInnerHTML={ { __html: field.html_label || field.label } }/>
                            }
                            inputRef={ register( {
                              validate: getBooleanFieldValidation( field ),
                            } ) }
                          />
                        </CustomerFieldContainer>
                      )
                    case BookingFieldType.MULTIPLE_CHOICE:
                      return (
                        <CustomerFieldContainer key={ field.id } className={ field.type }>
                          <Select
                            field={ field }
                            formState={ formState }
                            name={ field.id }
                            onChange={ ( value ) => onSelectFieldUpdated( field.id, value ) }
                            register={ register }
                          />
                        </CustomerFieldContainer>
                      )
                    case BookingFieldType.TEXT: {
                      let containerClass = field.input_type as string
                      let fieldMarginTop = field.input_type === BookingFieldTextInputType.NOTE ? "28px" : "16px"
                      let labelPosition: CSSProperties[ "position" ] = "absolute"

                      if( field.label.length > 23 ) {
                        containerClass += " full-width"
                        fieldMarginTop = "0"
                        labelPosition = "relative"
                      }

                      return (
                        <CustomerFieldContainer key={ field.id } className={ containerClass }>
                          <StyledTextField
                            name={ field.id }
                            id={ field.id }
                            defaultValue={ model.answers ? model.answers[ field.id ] : null }
                            error={ errors[ field.id ] ? true : false }
                            fullWidth={ true }
                            helperText={ formState.errors[ field.id ]?.message }
                            inputRef={ register( {
                              required: field.is_required ? "Please fill out this field" : false,
                              validate: ( value ) => getTextFieldValidation( value, field.input_type ),
                            } ) }
                            label={ field.label }
                            multiline={ field.input_type === BookingFieldTextInputType.NOTE }
                            rows={ field.input_type === BookingFieldTextInputType.NOTE ? 5 : 0 }
                            placeholder={ field.placeholder }
                            required={ field.is_required }
                            type={ getTextFieldType( field.input_type ) }
                            inputMode={ getInputMode( field.input_type ) }
                            InputProps={ {
                              disableUnderline: field.input_type === BookingFieldTextInputType.NOTE ? true : false,
                              style: {
                                border: field.input_type === BookingFieldTextInputType.NOTE ? `1px solid ${ BORDER_COLOR }` : "none",
                                borderRadius: field.input_type === BookingFieldTextInputType.NOTE ? "6px" : "none",
                                fontSize: "14px",
                                marginTop: fieldMarginTop,
                                padding: field.input_type === BookingFieldTextInputType.NOTE ? "10px" : "0px",
                              },
                            } }
                            InputLabelProps={ {
                              shrink: true,
                              style: {
                                color: TEXT_DARK_COLOR,
                                fontWeight: 500,
                                position: labelPosition,
                              },
                            } }
                          />
                        </CustomerFieldContainer>
                      )
                    }
                    default:
                      return null
                  }
                } )
              }
            </CustomerFieldsContainer>
          </BookingStepPageMainContentContainer>
        </BookingStepPageMainContainer>
        <BookingFooter buttonText="Complete Booking" disabled={ isProcessing } />
      </BookingStepPageContainer>
    </StyledForm>
  )
}
