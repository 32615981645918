import React from "react"
import styled from "styled-components"

import { CompletedIcon } from "../../../../components/Icon"
import { PageContainer, PageDescription, PageTitle } from "../../../../components/Page"
import { FONT_WEIGHT_MEDIUM, SUCCESS_COLOR } from "../../../../helpers/styles"
import { useCheckInState } from "../../components/CheckInContext"

const StyledTitle = styled.h1`
  color: ${ SUCCESS_COLOR };
  font-size: 18px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  margin-bottom: 40px;
  margin-top: 10px;
  text-align: center;
`

export interface CompletedProps {

}

export const CompletedPage: React.FunctionComponent<CompletedProps> = () => {
  const { settings } = useCheckInState()

  if( ! settings ) {
    return null
  }

  return (
    <PageContainer
      style={ {
        bottom: "auto",
        display: "flex",
        flexDirection: "column",
        top: "auto",
      } }
      showPoweredBy={ true }
    >
      <PageTitle>Thank you!</PageTitle>
      <CompletedIcon/>
      <StyledTitle>Registration Completed</StyledTitle>
      <PageDescription text={ settings.completed.text || "Please standby, we will send you a text message when we're available to see you" } />
    </PageContainer>
  )
}
