import React, { useCallback, useState } from "react"
import styled from "styled-components"

import { AssigneePlaceholderIcon } from "../../../../components/Icon"
import { LARGE_SCREEN_MEDIA_QUERY } from "../../../../helpers/responsive"
import { BookingStepPageContainer, BookingStepPageMainContainer, BookingStepPageMainContentContainer } from "../../components/BookingContainer"
import { useBookingState } from "../../components/BookingContext"
import { BookingFooter } from "../../components/BookingFooter"
import type { AssigneeOption, BookingAssigneeModel } from "../../types"
import { BookingStep } from "../../types"

export interface AssigneePageProps {
}

const AssigneeContainer = styled.div`
  align-items: center;
  border: 1px solid ${ props => props.theme.border };
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 84px;
  padding: 10px;

  & + & {
    margin-top: 20px;
  }
`

const AssigneeContentContainer = styled.div`
  flex: auto;
`

const AssigneeImageContainer = styled.div`
  flex: none;
  height: 64px;
  margin-right: 15px;
  width: 64px;
`

const AssigneeImage = styled.img`
  border-radius: 10px;
  height: 64px;
  width: 64px;
`

const AssigneeName = styled.div`
  color: ${ props => props.theme.text };
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 16px;
  }
`

const AssigneeTitle = styled.div`
  color: ${ props => props.theme.text };
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.2;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 16px;
  }
`

export const AssigneePage: React.FunctionComponent<AssigneePageProps> = () => {
  const { assigneeOptions, model, settings, setModel, setStep } = useBookingState()
  const [ assigneeModel, setAssigneeModel ] = useState<BookingAssigneeModel | null>( model.assignee ? { ...model.assignee } : null )

  const next = useCallback( () => {
    if( assigneeModel ) {
      setModel( { ...model, assignee: { ...assigneeModel } } )
      setStep( BookingStep.SCHEDULE )
    }
  }, [ model, assigneeModel, setModel, setStep ] )

  const setAssignee = useCallback( ( selectedAssigneeOption: AssigneeOption ) => {
    const updatedAssigneeModel: BookingAssigneeModel = {
      id: selectedAssigneeOption.value,
      name: selectedAssigneeOption.label,
      title: selectedAssigneeOption.title,
    }
    if( model.assignee?.id !== updatedAssigneeModel.id ) {
      setModel( { ...model, assignee: { ...updatedAssigneeModel }, schedule: undefined } )
    }
    setAssigneeModel( updatedAssigneeModel )
    setStep( BookingStep.SCHEDULE )
  }, [ model, setAssigneeModel, setModel, setStep ] )

  if( ! settings || ! settings.assignee ) {
    return null
  }

  return (
    <BookingStepPageContainer>
      <BookingStepPageMainContainer>
        <BookingStepPageMainContentContainer>
          {
            assigneeOptions.map( ( assigneeOption ) => (
              <AssigneeContainer
                key={ assigneeOption.value || "" }
                onClick={ () => setAssignee( assigneeOption ) }
                style={ assigneeModel?.id === assigneeOption.value ? { border: `2px solid ${ settings.design.colors.secondary.background }` } : {} }
              >
                <AssigneeImageContainer>
                  {
                    assigneeOption.image_url
                      ? (
                        <AssigneeImage alt={ assigneeOption.label } src={ assigneeOption.image_url }/>
                      )
                      : (
                        <AssigneePlaceholderIcon />
                      )
                  }
                </AssigneeImageContainer>
                <AssigneeContentContainer>
                  <AssigneeName>{ assigneeOption.label }</AssigneeName>
                  {
                    assigneeOption.title && (
                      <AssigneeTitle>{ assigneeOption.title }</AssigneeTitle>
                    )
                  }
                </AssigneeContentContainer>
              </AssigneeContainer>
            ) )
          }
        </BookingStepPageMainContentContainer>
      </BookingStepPageMainContainer>
      <BookingFooter buttonText="Next" disabled={ settings.assignee.is_required && ! assigneeModel } onButtonClick={ next } />
    </BookingStepPageContainer>
  )
}
