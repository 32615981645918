import React from "react"
import styled from "styled-components"

import { PoweredByOneLocal } from "../../../../components/PoweredByOneLocal"
import { LARGE_SCREEN_MEDIA_QUERY } from "../../../../helpers/responsive"
import { BookingStepPageContainer } from "../../components/BookingContainer"
import { useBookingState } from "../../components/BookingContext"
import { BookingSummary } from "../../components/BookingSummary"
import { CalendarEventStatusType } from "../../types"

export interface CreatedPageProps {
}

const CreatedPageContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 620px;
  justify-content: center;
  padding: 30px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    margin: 0 auto;
    padding: 0;
  }
`

const CreatedPageDescriptionContainer = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  margin-top: 26px;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 16px;
    margin-top: 17px;
  }
`

const CreatedPageButton = styled.button`
  background: ${ props => props.theme.primary.background };
  border: 1px solid ${ props => props.theme.primary.text };
  border-radius: 6px;
  color: ${ props => props.theme.primary.text };
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 45px;
  outline: none;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    width: 220px;
  }
`

const CreatedPageButtonContainer = styled.div`
  margin: 40px auto 0 auto;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    display: table;
    width: auto;
  }
`

const CreatedPageStatusContainer = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-top: 30px;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 24px;
    margin-top: 50px;
  }
`

const CreatedPageSummaryContainer = styled.div`
  margin-top: 28px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    margin-top: 55px;
  }
`

const CreatedPageTitleContainer = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 48px;
    margin-top: 64px;
  }

  & img {
    max-height: 100px;
    max-width: 100%;

    @media ${ LARGE_SCREEN_MEDIA_QUERY } {
      max-height: 100px;
      max-width: 270px;
    }
  }
`

const CreatedPageContainerPoweredByOneLocalContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    margin-bottom: 50px;
  }
`

export const CreatedPage: React.FunctionComponent<CreatedPageProps> = () => {
  const { calendarEvent, isPreview, settings } = useBookingState()

  if( ! settings || ! calendarEvent ) {
    return null
  }

  const callToAction = () => {
    const webBookerUrl = window.location.origin + window.location.pathname
    window.location.href = isPreview && settings.create.call_to_action.url === webBookerUrl ? `${ webBookerUrl }?mode=preview` : settings.create.call_to_action.url as string
  }

  const getBookingStatusDescription = () => {
    switch( calendarEvent.status.type ) {
      case CalendarEventStatusType.PENDING:
        return settings.pending.description
      case CalendarEventStatusType.CONFIRMED:
        return settings.confirm.description
    }
  }

  const getBookingStatusText = () => {
    switch( calendarEvent.status.type ) {
      case CalendarEventStatusType.PENDING:
        return settings.pending.text
      case CalendarEventStatusType.CONFIRMED:
        return settings.confirm.text
    }
  }

  return (
    <BookingStepPageContainer style={ { backgroundColor: settings.design.colors.primary.background, color: settings.design.colors.primary.text } }>
      <CreatedPageContentContainer>
        <CreatedPageTitleContainer>
          {
            settings!.logo_url
              ? (
                <img src={ settings!.logo_url } alt={ settings!.merchant.name } />
              ) : (
                <span>{ settings!.merchant.name }</span>
              )
          }
        </CreatedPageTitleContainer>
        <CreatedPageStatusContainer>
          { getBookingStatusText() }
        </CreatedPageStatusContainer>
        <CreatedPageDescriptionContainer>
          { getBookingStatusDescription() }
        </CreatedPageDescriptionContainer>
        <CreatedPageSummaryContainer>
          <BookingSummary />
        </CreatedPageSummaryContainer>
        {
          settings.create.call_to_action.enabled && (
            <CreatedPageButtonContainer>
              <CreatedPageButton onClick={ callToAction }>{ settings.create.call_to_action.text }</CreatedPageButton>
            </CreatedPageButtonContainer>
          )
        }
        { settings.design.powered_by?.enabled && (
          <CreatedPageContainerPoweredByOneLocalContainer>
            <PoweredByOneLocal />
          </CreatedPageContainerPoweredByOneLocalContainer>
        )
        }
      </CreatedPageContentContainer>
    </BookingStepPageContainer>
  )
}
