import React from "react"
import { Helmet } from "react-helmet"
import { BrowserRouter } from "react-router-dom"

import { CheckInContainer } from "./components/CheckInContainer"
import { CheckInContextProvider } from "./components/CheckInContext"

export const CheckInRoot: React.FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>Check In</title>
      </Helmet>
      <BrowserRouter>
        <CheckInContextProvider>
          <CheckInContainer />
        </CheckInContextProvider>
      </BrowserRouter>
    </>
  )
}
