
export enum ConfigEnv {
  DEV = "dev",
  STG = "stg",
  PRD = "prd",
}

export const config = {
  env: process.env.REACT_APP_ENV as ConfigEnv,
  gataweb_version: process.env.REACT_APP_GATAWEB_VERSION as string,
  stripe_public_key: process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
}
