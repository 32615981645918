import React from "react"
import { Helmet } from "react-helmet"
import { BrowserRouter } from "react-router-dom"

import { PaymentContainer } from "./components/PaymentContainer"
import { PaymentContextProvider } from "./components/PaymentContext"

export const PaymentRoot: React.FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>Payment</title>
      </Helmet>
      <BrowserRouter>
        <PaymentContextProvider>
          <PaymentContainer />
        </PaymentContextProvider>
      </BrowserRouter>
    </>
  )
}
