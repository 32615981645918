import React, { useImperativeHandle, useMemo, useState } from "react"
import styled from "styled-components"
import { Key } from "ts-keycode-enum"

import { LINK_COLOR } from "../helpers/styles"

export type VerificationCodeInputProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children" | "onChange"> & {
  onChange?( value: string ): void
  onComplete?( value: string ): void
}

const StyledInput = styled.input`
  -webkit-appearance: initial;
  border-radius: 0;
  border-right: none;
  border: solid 1px #a8adb7;
  box-sizing: border-box;
  color: #525461;
  font-size: 20px;
  height: 54px;
  text-align: center;
  width: 58px;

  &:last-child {
    border-right: solid 1px #a8adb7;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:focus {
    outline: none;
    border: 1px solid ${ LINK_COLOR };
    caret-color: ${ LINK_COLOR };
  }

  &:focus + & {
    border-left: none;
  }
`

export interface VerificationCodeInputRef {
  focus(): void
  reset(): void
}

export const VerificationCodeInput = React.forwardRef<VerificationCodeInputRef, VerificationCodeInputProps>( ( { onComplete, onChange, ...props }, ref ) => {
  const fields = 4
  const [ values, setValues ] = useState( Array( fields ).fill( "" ) )

  const textFieldRefs = useMemo( () => {
    return values.map( () => React.createRef<HTMLInputElement>() )
  }, [ values ] )

  useImperativeHandle( ref, () => ( {
    focus: () => {
      if( textFieldRefs.length > 0 && textFieldRefs[ 0 ].current ) {
        textFieldRefs[ 0 ].current.focus()
      }
    },
    reset: () => {
      // Focus on the first field before reset
      if( textFieldRefs.length > 0 && textFieldRefs[ 0 ].current ) {
        textFieldRefs[ 0 ].current.focus()
      }
      setValues( Array( fields ).fill( "" ) )
    },
  } ) )

  const onKeyDownHandler = ( event: React.KeyboardEvent<HTMLInputElement>, index: number ) => {
    const prevIndex = index - 1
    const nextIndex = index + 1
    const prev = textFieldRefs[ prevIndex ]
    const next = textFieldRefs[ nextIndex ]
    switch( event.keyCode ) {
      case Key.Backspace: {
        event.preventDefault()
        const newValues = [ ...values ]
        if( values[ index ] ) {
          newValues[ index ] = ""
          setValues( newValues )
          triggerOnChange( newValues )
        } else if( prev ) {
          newValues[ prevIndex ] = ""
          if( prev.current ) {
            prev.current.focus()
          }
          setValues( newValues )
          triggerOnChange( newValues )
        }
        break
      }
      case Key.LeftArrow:
        event.preventDefault()
        if( prev?.current ) {
          prev.current.focus()
        }
        break
      case Key.RightArrow:
        event.preventDefault()
        if( next?.current ) {
          next.current.focus()
        }
        break
      case Key.DownArrow:
      case Key.UpArrow:
        event.preventDefault()
        break
      default:
        break
    }
  }

  const onChangeHandler = ( event: React.ChangeEvent<HTMLInputElement>, index: number ) => {
    event.target.value = event.target.value.replace( /[^\d]/gi, "" )

    if( event.target.value === "" || ! event.target.validity.valid ) {
      return
    }

    let next: React.RefObject<HTMLInputElement>
    const value = event.target.value

    const newValues = [ ...values ]
    if( value.length > 1 ) {
      let nextIndex = value.length + index - 1
      if( nextIndex >= fields ) {
        nextIndex = fields - 1
      }
      next = textFieldRefs[ nextIndex ]
      const split = value.split( "" )
      split.forEach( ( item, i ) => {
        const cursor = index + i
        if( cursor < fields ) {
          values[ cursor ] = item
        }
      } )
    } else {
      next = textFieldRefs[ index + 1 ]
      newValues[ index ] = value
    }

    setValues( newValues )

    if( next?.current ) {
      next.current.focus()
      next.current.select()
    }

    triggerOnChange( newValues )
  }

  const triggerOnChange = ( newValues: string[] ) => {
    const value = newValues.join( "" )
    if( onChange ) {
      onChange( value )
    }

    if( onComplete && value.length >= fields && textFieldRefs[ fields - 1 ].current === document.activeElement ) {
      onComplete( value )
    }
  }

  return (
    <div { ...props }>
      {
        values.map( ( value, index ) => (
          <StyledInput
            key={ `${ index }` }
            type="tel"
            pattern="[0-9]*"
            value={ value }
            onKeyDown={ ( e ) => onKeyDownHandler( e, index ) }
            ref={ textFieldRefs[ index ] }
            data-id={ index }
            onChange={ ( e ) => onChangeHandler( e, index ) }
          />
        ) )
      }
    </div>
  )
} )
