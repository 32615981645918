import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import { AddIcon, CheckmarkIcon, MinusIcon } from "../../../../components/Icon"
import { ViewMore } from "../../../../components/ViewMore"
import { getVisitBookingsServiceAssignees } from "../../../../helpers/api"
import { logError } from "../../../../helpers/error"
import { LARGE_SCREEN_MEDIA_QUERY } from "../../../../helpers/responsive"
import { roundDecimal } from "../../../../helpers/utils"
import { BookingStepPageContainer, BookingStepPageMainContainer, BookingStepPageMainContentContainer } from "../../components/BookingContainer"
import type { BookingContextModel } from "../../components/BookingContext"
import { isBasicServiceType, isWindowServiceType, useBookingState } from "../../components/BookingContext"
import { BookingFooter } from "../../components/BookingFooter"
import type { AssigneeOption, ServiceType } from "../../types"
import { BookingServiceType, BookingStep, ScheduleStep, ScheduleType } from "../../types"

export interface ServiceProps {
}

const ServicePageGroupContainer = styled.div`
  border-bottom: 1px solid #ffffff;
  color: ${ props => props.theme.secondary.text };
  overflow: hidden;

  &:first-child {
    border-radius: 10px 10px 0 0;
  }

  &:last-child {
    border-bottom: none;
  }
`

const ServicePageGroupTitleContainer = styled.div`
  align-items: center;
  background: ${ props => props.theme.secondary.background };
  color: ${ props => props.theme.secondary.text };
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 20px;
`

const ServicePageGroupTitleIconContainer = styled.div`
  cursor: pointer;
  flex: 0 1 auto;
  margin-right: 10px;
`

const ServicePageGroupTitleText = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: bold;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 18px;
  }
`

const ServicePageGroupTypesContainer = styled.div`
  border-bottom: 1px solid ${ props => props.theme.border };
  border-left: 1px solid ${ props => props.theme.border };
  border-right: 1px solid ${ props => props.theme.border };
  padding: 30px 20px;
`

const ServicePageGroupTypeContainer = styled.div`
  border: 2px solid ${ props => props.theme.border };
  border-radius: 10px;
  box-sizing: border-box;
  color: ${ props => props.theme.text };
  display: flex;
  flex-direction: row;
  min-height: 84px;
  padding: 20px;

  & + & {
    margin-top: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`

const ServicePageGroupTypeCheckmarkContainer = styled.div`
  flex: 0 1 auto;
  height: 100%;
  margin-right: 15px;
`

const ServicePageGroupTypeCheckmarkPlaceholderIcon = styled.div`
  background: #DDD;
  border-radius: 50%;
  height: 24px;
  width: 24px;
`

const ServicePageGroupTypeDetailsContainer = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 14px;
  }
`

const ServicePageGroupTypeInformationContainer = styled.div`
  flex: 1;
  height: 100%;
`

const ServicePageGroupTypeDescription = styled.div`
  font-size: 12px;
  margin-top: 10px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 14px;
  }
`

const ServicePageGroupTypeTitle = styled.div`
  color: ${ props => props.theme.text };
  font-size: 14px;
  font-weight: 500;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 16px;
  }
`
interface DisplayGroupMap {
  [ groupId: string ]: boolean
}

export const ServicePage: React.FunctionComponent<ServiceProps> = () => {
  const { model, settings, setAssigneeOptions, setModel, setScheduleStep, setStep } = useBookingState()
  const defaultDisplayGroupMap: DisplayGroupMap = {}

  const [ displayGroupMap, setDisplayGroupMap ] = useState<DisplayGroupMap>( defaultDisplayGroupMap )
  const [ serviceModel, setServiceModel ] = useState<{ group_id: string, type_id: string } | null>( model.service ? { ...model.service } : null )

  useEffect( () => {
    if( model.service ) {
      setDisplayGroupMap( { [ model.service.group_id ]: true } )
      return
    }

    let isDefaultDisplayGroupSet = false
    const displayGroups: { [ groupId: string ]: boolean } = {}
    for( const group of settings!.service.groups ) {
      if( group.is_collapsed === false ) {
        isDefaultDisplayGroupSet = true
        displayGroups[ group.id ] = true
      }
    }

    if( ! isDefaultDisplayGroupSet && settings!.service.groups.length ) {
      setDisplayGroupMap( { [ settings!.service.groups[ 0 ].id ]: true } )
    } else {
      setDisplayGroupMap( displayGroups )
    }
  }, [ model, setModel, settings ] )

  const getServiceTypeContainerStyles = useCallback( ( type: ServiceType ) => {
    const styles: React.CSSProperties = {}

    if( serviceModel?.type_id === type.id ) {
      styles.border = `2px solid ${ settings!.design.colors.secondary.background }`
    }

    if( type.type === BookingServiceType.WINDOW && ! type.description ) {
      styles.alignItems = "center"
    }

    return styles
  }, [ serviceModel, settings ] )

  const next = useCallback( () => {
    if( serviceModel ) {
      const selectedServiceGroup = settings!.service.groups.find( ( group ) => group.id === serviceModel.group_id )
      const selectedServiceType = selectedServiceGroup!.types.find( ( type ) => type.id === serviceModel.type_id )

      let schedule: BookingContextModel[ "schedule" ]

      if( isWindowServiceType( selectedServiceType! ) && selectedServiceType.allow_asap ) {
        if( ! selectedServiceType.time_slot_ids?.length ) {
          schedule = {
            type: ScheduleType.ASAP,
          }
        }
        setScheduleStep( ScheduleStep.ASAP )
      } else {
        setScheduleStep( ScheduleStep.SELECT_TIME )
      }

      if( model.service?.type_id !== serviceModel.type_id ) {
        setModel( { ...model, service: { ...serviceModel }, assignee: undefined, schedule } )
      }

      if( settings!.assignee?.enabled && isBasicServiceType( selectedServiceType! ) ) {
        setAssigneeOptions( [] )

        getVisitBookingsServiceAssignees( settings!.merchant.slug, serviceModel.group_id, serviceModel.type_id )
          .then(
            ( serviceAssignees ) => {
              if( ! serviceAssignees?.length ) {
                setStep( BookingStep.SCHEDULE )
                return
              }

              const updatedAssigneeOptions: AssigneeOption[] = []
              if( settings!.assignee!.allow_any_option ) {
                updatedAssigneeOptions.push( {
                  label: "Any Staff Member",
                  value: null,
                } )
              }

              for( const assignee of serviceAssignees ) {
                updatedAssigneeOptions.push( {
                  image_url: assignee.image_url,
                  label: assignee.name.display,
                  title: assignee.title,
                  value: assignee.id,
                } )
              }

              setAssigneeOptions( updatedAssigneeOptions )
              setStep( BookingStep.ASSIGNEE )
            },
            ( error ) => {
              setStep( BookingStep.ERROR )
              logError( error )
            },
          )

        return
      }

      if( schedule?.type === ScheduleType.ASAP ) {
        setStep( BookingStep.CUSTOMER_INFO )
        return
      }

      setStep( BookingStep.SCHEDULE )
    }
  }, [ model, serviceModel, settings, setAssigneeOptions, setModel, setScheduleStep, setStep ] )

  const toggleDisplayGroup = useCallback( ( groupId: string ) => {
    setDisplayGroupMap( { ...displayGroupMap, [ `${ groupId }` ]: ! displayGroupMap[ groupId ] } )
  }, [ displayGroupMap ] )

  const selectServiceType = useCallback( ( groupId: string, typeId: string ) => {
    if( serviceModel?.group_id !== groupId || serviceModel?.type_id !== typeId ) {
      setServiceModel( { group_id: groupId, type_id: typeId } )
    }
  }, [ serviceModel ] )

  if( ! settings ) {
    return null
  }

  const headerIconStyle: React.CSSProperties = {
    fill: `${ settings.design.colors.secondary.text }`,
  }

  const checkmarkIconStyle: React.CSSProperties = {
    fill: `${ settings.design.colors.secondary.background }`,
  }

  return (
    <BookingStepPageContainer>
      <BookingStepPageMainContainer>
        <BookingStepPageMainContentContainer>
          {
            settings.service.groups.map( ( group, index ) =>
              (
                <ServicePageGroupContainer key={ group.id } style={ index === settings.service.groups.length - 1 && ! displayGroupMap[ group.id ] ? { borderRadius: "0 0 10px 10px" } : {} }>
                  <ServicePageGroupTitleContainer onClick={ () => settings.service.groups.length > 1 && toggleDisplayGroup( group.id ) }>
                    {
                      settings.service.groups.length > 1 && (
                        <ServicePageGroupTitleIconContainer>
                          {
                            displayGroupMap[ group.id ]
                              ? <MinusIcon style={ headerIconStyle } />
                              : <AddIcon style={ headerIconStyle } />
                          }
                        </ServicePageGroupTitleIconContainer>
                      )
                    }
                    <ServicePageGroupTitleText>
                      { group.label }
                    </ServicePageGroupTitleText>
                  </ServicePageGroupTitleContainer>
                  {
                    displayGroupMap[ group.id ] && (
                      <ServicePageGroupTypesContainer style={ index === settings.service.groups.length - 1 ? { borderRadius: "0 0 10px 10px" } : {} }>
                        {
                          group.types.map( ( type ) => (
                            <ServicePageGroupTypeContainer
                              key={ type.id }
                              onClick={ () => selectServiceType( group.id, type.id ) }
                              style={ getServiceTypeContainerStyles( type ) }
                            >
                              <ServicePageGroupTypeCheckmarkContainer style={ isBasicServiceType( type ) || type.description ? { marginTop: "10px" } : {} }>
                                {
                                  serviceModel?.type_id === type.id
                                    ? (
                                      <CheckmarkIcon style={ checkmarkIconStyle } />
                                    )
                                    : (
                                      <ServicePageGroupTypeCheckmarkPlaceholderIcon />
                                    )
                                }
                              </ServicePageGroupTypeCheckmarkContainer>
                              <ServicePageGroupTypeInformationContainer>
                                <ServicePageGroupTypeTitle>
                                  { type.label }
                                </ServicePageGroupTypeTitle>
                                {
                                  type.description && (
                                    <ServicePageGroupTypeDescription>
                                      <ViewMore text={ type.description } linkColor={ settings.design.colors.hyperlink } />
                                    </ServicePageGroupTypeDescription>
                                  )
                                }
                                {
                                  isBasicServiceType( type ) && (
                                    <ServicePageGroupTypeDetailsContainer>
                                      {
                                        type.cost && (
                                          <span>${ roundDecimal( type.cost.amount / 100, 2 ) } &#8226;&nbsp;</span>
                                        )
                                      }
                                      <span>{ type.duration / 60 } min</span>
                                    </ServicePageGroupTypeDetailsContainer>
                                  )
                                }
                              </ServicePageGroupTypeInformationContainer>
                            </ServicePageGroupTypeContainer>
                          ) )
                        }
                      </ServicePageGroupTypesContainer>
                    )
                  }
                </ServicePageGroupContainer>
              ),
            )
          }
        </BookingStepPageMainContentContainer>
      </BookingStepPageMainContainer>
      <BookingFooter buttonText="Next" disabled={ ! serviceModel?.type_id } onButtonClick={ next } />
    </BookingStepPageContainer>
  )
}
