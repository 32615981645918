import React from "react"
import styled from "styled-components"

import { LARGE_SCREEN_MEDIA_QUERY } from "../../../helpers/responsive"

const StyledBookingProgressBarContainer = styled.div`
  height: 6px;
  margin-bottom: 4px;
  position: fixed;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    height: 10px;
    margin-bottom: 5px;
  }
`

export interface BookingProgressBarContainerProps {
  style: React.CSSProperties
}

export const BookingProgressBarContainer: React.FunctionComponent<BookingProgressBarContainerProps> = ( { style } ) => {
  return (
    <StyledBookingProgressBarContainer style={ style } />
  )
}
