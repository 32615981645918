import { loadStripe } from "@stripe/stripe-js"
import React, { createContext, useState } from "react"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import { ErrorPage } from "../../../../components/ErrorPage"
import { PageContainer } from "../../../../components/Page"
import { Spinner } from "../../../../components/Spinner"
import { config } from "../../../../helpers/config"
import { usePaymentState } from "../../components/PaymentContext"

const StyledSpinner = styled( Spinner ) `
  margin-bottom: 10px;
`

const StyledLoadingText = styled.div `
  font-size: 14px;
  text-align: center;
`

interface PendingPageContextType {
}

export const PendingPageContext = createContext<PendingPageContextType>( null! )

// eslint-disable-next-line @typescript-eslint/ban-types
export const PendingPage: React.FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const [ hasError, setHasError ] = useState<boolean>( false )
  const { paymentSummary } = usePaymentState()

  useEffectOnce( () => {
    const loadStripeCheckout = async () => {
      if( paymentSummary ) {
        try {
          const stripe = await loadStripe( config.stripe_public_key, { stripeAccount: paymentSummary.stripe.connected_account_id } )
          if( stripe && paymentSummary.stripe.checkout_session_id ) {
            const { error } = await stripe.redirectToCheckout( { sessionId: paymentSummary.stripe.checkout_session_id } )
            setHasError( error != null )
          }
        } catch( err ) {
          setHasError( true )
        }
      }
    }

    loadStripeCheckout()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } )

  return (
    hasError
      ? (
        <ErrorPage/>
      ) : (
        <PageContainer showPoweredBy={ true }>
          <StyledSpinner />
          <StyledLoadingText>Please wait while you are being redirected to a checkout page...</StyledLoadingText>
        </PageContainer>
      )
  )
}
