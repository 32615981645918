// eslint-disable-next-line no-useless-escape
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const INTEGER_REGEX = /^[-]?\d+$/
const PHONE_REGEX = /^\+*\d{9,15}$/

export function isEmail( input: string ) {
  return EMAIL_REGEX.test( input )
}

export function isInteger( input: string ) {
  return INTEGER_REGEX.test( input )
}

export function isPhone( input: string ) {
  return PHONE_REGEX.test( input )
}
