import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import { createMuiTheme, ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles"
import React, { useCallback, useState } from "react"
import { Helmet } from "react-helmet"
import { matchPath } from "react-router"
import { useLocation } from "react-router-dom"
import { animated, useTransition } from "react-spring"
import { useEffectOnce, useMountedState, usePrevious } from "react-use"
import styled, { ThemeProvider as StyledComponentThemeProvider } from "styled-components"

import { ErrorPage } from "../../../components/ErrorPage"
import { AddIcon, BackIcon, MinusIcon, WarningIcon } from "../../../components/Icon"
import { FullPageContainer } from "../../../components/Page"
import { Spinner } from "../../../components/Spinner"
import { ViewMore } from "../../../components/ViewMore"
import { getVisitBookingsSettings } from "../../../helpers/api"
import { logError } from "../../../helpers/error"
import { EXTRA_LARGE_SCREEN_MEDIA_QUERY, isGreaterThanMediumScreen, LARGE_SCREEN_MEDIA_QUERY } from "../../../helpers/responsive"
import { ERROR_COLOR, FONT_WEIGHT_MEDIUM, WARNING_COLOR } from "../../../helpers/styles"
import { AssigneePage } from "../pages/AssigneePage/AssigneePage"
import { CreatedPage } from "../pages/CreatedPage/CreatedPage"
import { CustomerPage } from "../pages/CustomerPage/CustomerPage"
import { SchedulePage } from "../pages/SchedulePage/SchedulePage"
import { ServicePage } from "../pages/ServicePage/ServicePage"
import { BookingStep, ScheduleStep } from "../types"
import { isWindowServiceType, useBookingState } from "./BookingContext"
import { BookingProgressBarContainer } from "./BookingProgressBarContainer"
import { BookingSummary } from "./BookingSummary"

const BookingPageStepContainer = styled.div`
  display: flex;
  flex: 1;
`

const BookingCreatedPageContainer = styled.div`
  display: flex;
  flex: 1;
`

const BookingLeftPanelBackText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
`

const BookingLeftPanelBackIcon = styled( BackIcon )`
  height: 18px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    height: 16px;
  }
`

const BookingLeftPanelBackIconContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  left: 30px;
  position: absolute;
  top: 40px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    bottom: 32px;
    left: 50px;
    top: auto;
  }
`

const BookingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    flex-direction: row;
  }
`

const BookingPageFlexContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const BookingMainContainer = styled.div`
  display: flex;
  flex: 1;
`

const BookingLeftPanelContainer = styled.div`
  background: ${ props => props.theme.primary.background };
  color: ${ props => props.theme.primary.text };
  flex: 0 1 auto;
  position: relative;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    flex: 1 0 0;
    height: 100%;
  }
`

const BookingLeftPanelDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 16px;
    line-height: 1.4;
    max-height: 100px;
    overflow-y: auto;
    text-align: left;
  }

  @media ${ EXTRA_LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 16px;
    line-height: 1.4;
    max-height: 115px;
    overflow-y: auto;
    text-align: left;
  }
`

const BookingLeftPanelInnerContainer = styled.div`
  padding: 20px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    padding: 0 50px;
  }
`

const BookingLeftPanelStepText = styled.div`
  font-size: 24px;
  font-weight: 500;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 24px;
    line-height: 1.2;
    text-align: left;
  }

  @media ${ EXTRA_LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 24px;
    line-height: 1.2;
    text-align: left;
  }
`

const BookingLeftPanelSummaryContainer = styled.div`
  margin-top: 40px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    bottom: 90px;
    left: 50px;
    margin-top: 0px;
    position: absolute;
    right: 50px;
  }
`

const BookingLeftPanelSummaryTitleContainer = styled.div`
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 13px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 24px;
  }
`

const BookingLeftPanelSummaryTitleIconContainer = styled.div`
  display: inline;
`

const BookingLeftPanelSummaryTitleText = styled.span`
  font-size: 16px;
  margin-left: 11px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 24px;
  }
`

const BookingLeftPanelTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: 8px 30px 20px 30px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 48px;
    line-height: 72px;
    margin: 50px 0 0 0;
    text-align: left;
  }

  & img {
    max-height: 100px;
    max-width: 100%;

    @media ${ LARGE_SCREEN_MEDIA_QUERY } {
      max-height: 100px;
      max-width: 270px;
    }
  }
`

const StyledLoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const StyledPreviewContainer = styled.div`
  align-items: center;
  background-color: ${ WARNING_COLOR };
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 64px;
  padding: 12px 20px;
  position: fixed;
  top: 0;
  width: 100%;
`

const StyledPreviewContainerText = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  margin-left: 5px;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    margin-left: 16px;
    font-size: 14px;
  }
`

export const BookingStepPageContainer = styled.div`
  height: 100%;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    position: relative;
  }
`

export const BookingStepPageMainContainer = styled.div`
  display: flex;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    height: calc( 100% - 120px );
    overflow-y: auto;
    padding-top: 40px;
  }
`

export const BookingStepPageMainContentContainer = styled.div`
  margin: 30px;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    margin: auto;
    width: 70%;
  }
`

const StyledSnackbar = styled( Snackbar )`
  .MuiSnackbarContent-root {
    background-color: ${ ERROR_COLOR };
    color: #ffffff;
    font-weight: 500;
  }
`

export const BookingContainer: React.FunctionComponent = () => {
  const {
    assigneeOptions,
    errorMessage,
    isPreview,
    model,
    scheduleStep,
    settings,
    step,
    setErrorMessage,
    setModel,
    setScheduleStep,
    setSettings,
    setStep,
    setIsPreview,
  } = useBookingState()
  const isMounted = useMountedState()
  const location = useLocation()
  const previousStep = usePrevious( step )
  const [ isLoading, setIsLoading ] = useState<boolean>( true )
  const [ displayBookingSummary, setDisplayBookingSummary ] = useState<boolean>( true )
  const [ isMobileDevice, setIsMobileDevice ] = useState<boolean>( ! isGreaterThanMediumScreen() )
  const [ stepsOrder, setStepsOrder ] = useState<BookingStep[]>(
    [
      BookingStep.SERVICE,
      BookingStep.ASSIGNEE,
      BookingStep.SCHEDULE,
      BookingStep.CUSTOMER_INFO,
      BookingStep.CREATED,
    ],
  )

  const currentStepIndex = step ? stepsOrder.indexOf( step ) : 0

  const transitions = useTransition(
    step,
    {
      enter: { opacity: 1 },
      from: { opacity: 0 },
      immediate: ! isMounted() || ! previousStep,
      key: step,
      leave: { opacity: 0 },
    },
  )

  useEffectOnce( () => {
    const urlParams = new URLSearchParams( location.search )
    const mode = urlParams.get( "mode" )
    if( mode === "preview" ) {
      setIsPreview( true )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const routes: Array<{ path: string, handler( params: any ): void }> = [
      {
        path: "/booking/:slug",
        handler( params: { slug: string } ) {
          const utmTag = urlParams.get( "utm_source" )

          getVisitBookingsSettings( params.slug ).then(
            ( bookingSettings ) => {
              setSettings( bookingSettings )
              setStep( BookingStep.SERVICE )
              const updatedModel = {
                ...model,
              }

              if( utmTag ) {
                updatedModel.utm_source = utmTag
              }

              if( bookingSettings.location?.enabled ) {
                updatedModel.location = {
                  ...bookingSettings.location,
                }
              }

              if( ! bookingSettings.assignee?.enabled && stepsOrder.includes( BookingStep.ASSIGNEE ) ) {
                setStepsOrder( stepsOrder.filter( ( bookingStep ) => bookingStep !== BookingStep.ASSIGNEE ) )
              }

              setModel( updatedModel )
              setIsLoading( false )
            },
            ( error ) => {
              setStep( BookingStep.ERROR )
              logError( error )
              setIsLoading( false )
            } )
        },
      },
    ]

    for( const route of routes ) {
      const match = matchPath( route.path, location.pathname )

      if( match ) {
        route.handler( match.params )
        break
      }
    }
  } )

  const back = useCallback( () => {
    let backByIndex = 1

    if( step === BookingStep.SCHEDULE ) {
      if( scheduleStep && scheduleStep! === ScheduleStep.SELECT_TIME && model.service?.group_id && model.service?.type_id ) {
        const selectedServiceGroup = settings!.service.groups.find( ( group ) => group.id === model.service!.group_id )
        const selectedServiceType = selectedServiceGroup!.types.find( ( type ) => type.id === model.service!.type_id )

        if( isWindowServiceType( selectedServiceType! ) && selectedServiceType.allow_asap ) {
          setScheduleStep( ScheduleStep.ASAP )
          return
        }
      }

      if( ! assigneeOptions.length && stepsOrder.includes( BookingStep.ASSIGNEE ) ) {
        backByIndex = 2
      }
    } else if( step === BookingStep.CUSTOMER_INFO && model.service?.group_id && model.service?.type_id ) {
      const selectedServiceGroup = settings!.service.groups.find( ( group ) => group.id === model.service!.group_id )
      const selectedServiceType = selectedServiceGroup!.types.find( ( type ) => type.id === model.service!.type_id )

      if( isWindowServiceType( selectedServiceType! ) && selectedServiceType.allow_asap && ! selectedServiceType.time_slot_ids?.length ) {
        if( ! assigneeOptions.length && stepsOrder.includes( BookingStep.ASSIGNEE ) ) {
          backByIndex = 3
        } else {
          backByIndex = 2
        }
      }
    }

    setStep( stepsOrder[ currentStepIndex - backByIndex ] )
  }, [ assigneeOptions, currentStepIndex, model, scheduleStep, settings, step, stepsOrder, setScheduleStep, setStep ] )

  const getBookingPageStyle = useCallback( ( currentStep: BookingStep ): React.CSSProperties => {
    if( currentStep === BookingStep.CREATED ) {
      return { flex: "1", marginTop: isPreview ? "64px" : "0" }
    }
    let marginTopInPixels = isMobileDevice ? 10 : 15
    if( isPreview ) {
      marginTopInPixels += 64
    }
    const marginTop = `${ marginTopInPixels }px`
    const marginBottom = isMobileDevice ? "90px" : "0px"
    return { height: `calc( 100% - ${ marginTop } - ${ marginBottom } )`, marginTop, overflowY: "auto" }
  }, [ isMobileDevice, isPreview ] )

  const getStepSettings = ( currentStep: BookingStep ) => {
    if( ! settings ) {
      return {}
    }

    switch( currentStep ) {
      case BookingStep.SERVICE:
        return { description: settings.service.description, text: settings.service.text }
      case BookingStep.ASSIGNEE:
        return { description: settings.assignee?.description, text: settings.assignee?.text }
      case BookingStep.SCHEDULE:
        return { description: settings.schedule.description, text: settings.schedule.text }
      case BookingStep.CUSTOMER_INFO:
        return { description: settings.customer_info.description, text: settings.customer_info.text }
      default:
        return {}
    }
  }

  const renderStep = ( currentStep: BookingStep ) => {
    switch( currentStep ) {
      case BookingStep.SERVICE:
        return <ServicePage />
      case BookingStep.ASSIGNEE:
        return <AssigneePage />
      case BookingStep.SCHEDULE:
        return <SchedulePage />
      case BookingStep.CUSTOMER_INFO:
        return <CustomerPage />
      case BookingStep.CREATED:
        return <CreatedPage />
      default:
        return null
    }
  }

  const resizeHandler = useCallback( () => {
    setIsMobileDevice( ! isGreaterThanMediumScreen() )
  }, [ setIsMobileDevice ] )

  useEffectOnce( () => {
    window.addEventListener( "resize", resizeHandler )

    return () => window.removeEventListener( "resize", resizeHandler )
  } )

  const toggleBookingSummary = useCallback( () => {
    setDisplayBookingSummary( ! displayBookingSummary )
  }, [ displayBookingSummary, setDisplayBookingSummary ] )

  const handleClose = () => {
    setErrorMessage( null )
  }

  const handleExited = () => {
    setErrorMessage( null )
  }

  return (
    <FullPageContainer>
      {
        isLoading
          ? (
            <StyledLoadingContainer>
              <Spinner />
            </StyledLoadingContainer>
          )
          : (
            step === BookingStep.ERROR || ! settings
              ? (
                <ErrorPage />
              )
              : (
                <div>
                  {
                    settings.facebook_pixel_id && (
                      <Helmet>
                        <script type="text/javascript" async={ true }>
                          { `
                        if(typeof fbq === 'undefined') {
                          !function(f,b,e,v,n,t,s)
                          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                          n.queue=[];t=b.createElement(e);t.async=!0;
                          t.src=v;s=b.getElementsByTagName(e)[0];
                          s.parentNode.insertBefore(t,s)}(window, document,'script',
                          'https://connect.facebook.net/en_US/fbevents.js');
                          fbq('init', '${ settings.facebook_pixel_id }');
                        }
                        fbq('track', 'PageView');
                      ` }
                        </script>
                      </Helmet>
                    )
                  }
                  {
                    settings.google_analytics_site_id && (
                      <Helmet>
                        <script type="text/javascript" async={ true } src={ `https://www.googletagmanager.com/gtag/js?id=${ settings.google_analytics_site_id }` }/>
                        <script>
                          { `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${ settings.google_analytics_site_id }');
                      ` }
                        </script>
                      </Helmet>
                    )
                  }
                  {
                    transitions( ( transitionStyle, item ) => {
                      const stepIndex = stepsOrder.indexOf( item! )
                      let style: React.CSSProperties = {
                        display: "flex",
                        background: "white",
                        flex: 1,
                        height: "100%",
                        position: "absolute",
                        width: "100%",
                      }

                      if( ! isMobileDevice ) {
                        style = {
                          ...style,
                          overflowY: "scroll",
                        }
                      }

                      const progress = ( ( stepIndex + 1 ) / ( stepsOrder.filter( ( bookingStep ) => bookingStep !== BookingStep.CREATED ).length ) ) * 100
                      const bookingProgressBarStyle: React.CSSProperties = {
                        background: `${ settings.design.colors.secondary.background }`,
                        top: isPreview ? "64px" : "0",
                        width: `${ progress }%`,
                      }
                      const iconStyle: React.CSSProperties = {
                        fill: `${ settings.design.colors.primary.text }`,
                      }
                      const styledComponentTheme = settings.design.colors
                      const materialTheme = createMuiTheme( {
                        typography: {
                          fontFamily: "Gotham Rounded A, Gotham Rounded B",
                        },
                        palette: {
                          primary: { "500": settings.design.colors.primary.background },
                        },
                      } )

                      const { description, text } = getStepSettings( item! )

                      return (
                        <StyledComponentThemeProvider key={ item } theme={ styledComponentTheme }>
                          <MaterialThemeProvider theme={ materialTheme }>
                            <StyledSnackbar
                              anchorOrigin={ {
                                vertical: "top",
                                horizontal: "center",
                              } }
                              autoHideDuration={ null }
                              onClose={ handleClose }
                              onExited={ handleExited }
                              open={ errorMessage != null }
                            >
                              <SnackbarContent message={ errorMessage } />
                            </StyledSnackbar>
                            <animated.div style={ { ...transitionStyle, ...style } }>
                              <BookingPageFlexContainer>
                                <BookingPageContainer style={ getBookingPageStyle( item! ) }>
                                  {
                                    step !== BookingStep.CREATED && (
                                      <BookingLeftPanelContainer>
                                        <BookingLeftPanelInnerContainer>
                                          {
                                            stepIndex > 0 && (
                                              <BookingLeftPanelBackIconContainer onClick={ back }>
                                                <BookingLeftPanelBackIcon style={ iconStyle } />
                                                {
                                                  ! isMobileDevice && (
                                                    <BookingLeftPanelBackText>Back</BookingLeftPanelBackText>
                                                  )
                                                }
                                              </BookingLeftPanelBackIconContainer>
                                            )
                                          }
                                          <BookingLeftPanelTitle>
                                            {
                                              settings.logo_url
                                                ? (
                                                  <img src={ settings.logo_url } alt={ settings.merchant.name } />
                                                ) : (
                                                  <span>{ settings.merchant.name }</span>
                                                )
                                            }
                                          </BookingLeftPanelTitle>
                                          <BookingLeftPanelStepText>
                                            { text }
                                          </BookingLeftPanelStepText>
                                          {
                                            description && (
                                              <BookingLeftPanelDescription>
                                                <ViewMore
                                                  linkColor={ settings.design.colors.primary.text }
                                                  linkLessText="Read&nbsp;less"
                                                  linkMoreText="Read&nbsp;more"
                                                  text={ description }
                                                />
                                              </BookingLeftPanelDescription>
                                            )
                                          }
                                          {
                                            ( model.location || model.service || model.schedule ) && (
                                              <BookingLeftPanelSummaryContainer>
                                                <BookingLeftPanelSummaryTitleContainer onClick={ toggleBookingSummary }>
                                                  <BookingLeftPanelSummaryTitleIconContainer>
                                                    {
                                                      displayBookingSummary
                                                        ? (
                                                          <MinusIcon style={ iconStyle } />
                                                        ) : (
                                                          <AddIcon style={ iconStyle } />
                                                        )
                                                    }
                                                  </BookingLeftPanelSummaryTitleIconContainer>
                                                  <BookingLeftPanelSummaryTitleText>Booking Summary</BookingLeftPanelSummaryTitleText>
                                                </BookingLeftPanelSummaryTitleContainer>
                                                {
                                                  displayBookingSummary && <BookingSummary />
                                                }
                                              </BookingLeftPanelSummaryContainer>
                                            )
                                          }
                                        </BookingLeftPanelInnerContainer>
                                      </BookingLeftPanelContainer>
                                    )
                                  }
                                  <BookingMainContainer>
                                    {
                                      step !== BookingStep.CREATED
                                        ? (
                                          <BookingPageStepContainer>
                                            { renderStep( item! ) }
                                          </BookingPageStepContainer>
                                        ) : (
                                          <BookingCreatedPageContainer>
                                            { renderStep( item! ) }
                                          </BookingCreatedPageContainer>
                                        )
                                    }
                                  </BookingMainContainer>
                                </BookingPageContainer>
                                {
                                  isPreview && (
                                    <StyledPreviewContainer>
                                      <div>
                                        <WarningIcon></WarningIcon>
                                      </div>
                                      <StyledPreviewContainerText>You are in preview mode. You cannot book appointments while you are in preview mode.</StyledPreviewContainerText>
                                    </StyledPreviewContainer>
                                  )
                                }
                                {
                                  step !== BookingStep.CREATED && (
                                    <BookingProgressBarContainer style={ bookingProgressBarStyle }/>
                                  )
                                }
                              </BookingPageFlexContainer>
                            </animated.div>
                          </MaterialThemeProvider>
                        </StyledComponentThemeProvider>
                      )
                    } )
                  }
                </div>
              )
          )
      }
    </FullPageContainer>
  )
}
