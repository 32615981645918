import React, { useRef, useState } from "react"
import styled from "styled-components"

import { PrimaryButton } from "../../../../components/Button"
import { PageContainer } from "../../../../components/Page"
import { StyledA } from "../../../../components/Typography"
import type { VerificationCodeInputRef } from "../../../../components/VerificationCodeInput"
import { VerificationCodeInput } from "../../../../components/VerificationCodeInput"
import { sendPhoneNumberVerificationCode, verifyPhoneNumberVerificationCode } from "../../../../helpers/api"
import { ERROR_COLOR, FONT_WEIGHT_BOLD, FONT_WEIGHT_MEDIUM, SUCCESS_COLOR, TEXT_DARK_COLOR, TEXT_LIGHT_COLOR } from "../../../../helpers/styles"
import { useEffectTimeout } from "../../../../hooks/useEffectTimeout"
import { useLayoutEffectTimeout } from "../../../../hooks/useLayoutEffectTimeout"
import { useCheckInState } from "../../components/CheckInContext"
import { CheckInStep } from "../../types"

const StyledLabel = styled.label`
  display: block;
  font-size: 18px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  margin-bottom: 10px;
  text-align: center;
`

const StyledDescription = styled.div`
  color: ${ TEXT_LIGHT_COLOR };
  display: block;
  font-size: 16px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;

  strong {
    color: ${ TEXT_DARK_COLOR };
    font-weight: ${ FONT_WEIGHT_BOLD };
  }
`

const StyledPageContainer = styled( PageContainer )`
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
`

const StyledResendDescription = styled( StyledDescription )`
  margin-bottom: 10px;
`

const StyledVerificationCodeInput = styled( VerificationCodeInput )`
  margin-bottom: 20px;
`

const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const StyledError = styled.div`
  color: ${ ERROR_COLOR };
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
`

const StyledSuccessContainer = styled.div`
  color: ${ TEXT_LIGHT_COLOR };
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
`

const StyledSuccessText = styled.span`
  color: ${ SUCCESS_COLOR };
`

export interface PhoneNumberVerificationPageProps {
}

export const PhoneNumberVerificationPage: React.FunctionComponent<PhoneNumberVerificationPageProps> = () => {
  const { model, settings, setModel, setStep } = useCheckInState()
  const [ code, setCode ] = useState<string>( "" )
  const verificationCodeInputRef = useRef<VerificationCodeInputRef | null>( null )
  const [ errorMessage, setErrorMessage ] = useState<string | null>( null )
  const [ showSuccessMessage, setShowSuccessMessage ] = useState<boolean>( false )
  const [ isLoading, setIsLoading ] = useState<boolean>( false )

  useLayoutEffectTimeout(
    () => {
      if( verificationCodeInputRef.current ) {
        verificationCodeInputRef.current.focus()
      }
    },
    500,
  )

  useEffectTimeout( () => {
    if( showSuccessMessage ) {
      setShowSuccessMessage( false )
    }
  }, 3000, [ showSuccessMessage ] )

  if( ! settings ) {
    return null
  }

  const verificationCodeCompleteHandler = ( value: string ) => {
    verifyCode( value )
  }

  const verificationCodeChangedHandler = ( value: string ) => {
    setCode( value )
  }

  const submitHandler = ( event: React.FormEvent<HTMLFormElement> ) => {
    event.preventDefault()

    if( settings.preview && settings.location.enabled ) {
      setStep( CheckInStep.LOCATION )
    } else if( settings.preview ) {
      setStep( CheckInStep.INFORMATION )
    } else {
      verifyCode( code )
    }
  }

  const verifyCode = ( verificationCode: string ) => {
    setShowSuccessMessage( false )
    setErrorMessage( null )

    if( ! verificationCode || verificationCode.length < 4 ) {
      setErrorMessage( "Please enter the code sent to your number" )
      return
    }

    setIsLoading( true )

    verifyPhoneNumberVerificationCode( settings.merchant.slug, model.phoneNumber!, verificationCode ).then(
      () => {
        setModel( { ...model, phoneNumberVerificationCode: verificationCode } )
        if( settings!.location.enabled ) {
          setStep( CheckInStep.LOCATION )
        } else {
          setStep( CheckInStep.INFORMATION )
        }
      },
      ( error ) => {
        if( verificationCodeInputRef ) {
          verificationCodeInputRef.current!.reset()
        }

        setErrorMessage( error.message )
        setIsLoading( false )
      },
    )
  }

  const onChangePhoneNumberHandler = ( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
    event.preventDefault()
    setStep( CheckInStep.PHONE_NUMBER )
  }

  const resendHandler = ( event: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
    event.preventDefault()
    sendPhoneNumberVerificationCode( settings!.merchant.slug, model.phoneNumber! ).then(
      () => {
        setShowSuccessMessage( true )
      },
      ( error: Error ) => {
        setErrorMessage( error.message )
      },
    )
  }

  return (
    <StyledPageContainer isLoading={ isLoading }>
      <StyledForm onSubmit={ submitHandler } noValidate={ true }>
        <StyledLabel>Verify your mobile phone number</StyledLabel>
        <StyledDescription>
          A 4 digit code has been sent to
          <br />
          <strong>{ model.phoneNumber }</strong> <StyledA href="#" onClick={ onChangePhoneNumberHandler }>Change</StyledA></StyledDescription>
        <StyledVerificationCodeInput ref={ verificationCodeInputRef } onChange={ verificationCodeChangedHandler } onComplete={ verificationCodeCompleteHandler }/>
        <StyledResendDescription>Didn’t receive the code? <StyledA href="#" onClick={ resendHandler }>Resend</StyledA></StyledResendDescription>
        {
          errorMessage && (
            <StyledError>{ errorMessage }</StyledError>
          )
        }
        {
          showSuccessMessage && (
            <StyledSuccessContainer>The code has been resent to <StyledSuccessText>{ model.phoneNumber }</StyledSuccessText></StyledSuccessContainer>
          )
        }
        <PrimaryButton type="submit">Next</PrimaryButton>
      </StyledForm>
    </StyledPageContainer>
  )
}
