import React, { useImperativeHandle, useRef } from "react"

import type { CheckInSettingsField, FieldAnswer } from "../../types"
import { CheckInSettingsFieldType } from "../../types"
import { InformationFieldMultiChoice } from "./InformationFieldMultiChoice"
import { InformationFieldText } from "./InformationFieldText"

export interface InformationFieldProps {
  fieldSettings: CheckInSettingsField
  initialAnswer: FieldAnswer | null
  onCompleted( answer: FieldAnswer ): void
  onValidityChanged( isValid: boolean ): void
  onIsFocusModeChanged( isFocusMode: boolean ): void
}

export interface InformationFieldRef {
  submit(): void
}

export const InformationField = React.forwardRef<InformationFieldRef, InformationFieldProps>( ( props, ref ) => {
  const { fieldSettings } = props
  const fieldRef = useRef<InformationFieldRef>( null )

  useImperativeHandle( ref, () => ( {
    submit: () => {
      if( fieldRef.current ) {
        fieldRef.current.submit()
      }
    },
  } ) )

  switch( fieldSettings.type ) {
    case CheckInSettingsFieldType.MULTIPLE_CHOICE:
      return (
        <InformationFieldMultiChoice
          { ...props as any } // eslint-disable-line @typescript-eslint/no-explicit-any
          ref={ fieldRef }
        />
      )
    case CheckInSettingsFieldType.TEXT:
      return (
        <InformationFieldText
          { ...props as any } // eslint-disable-line @typescript-eslint/no-explicit-any
          ref={ fieldRef }
        />
      )
    default:
      throw new Error( "Unsupported type" )
  }
} )
