export const BORDER_COLOR = "#BBB"
export const ERROR_COLOR = "#EC3939"
export const FONT_FAMILY = "\"Gotham Rounded A\", \"Gotham Rounded B\""
export const FONT_WEIGHT_BOLD = 700
export const FONT_WEIGHT_MEDIUM = 500
export const FONT_WEIGHT_NORMAL = 400
export const LIGHT_COLOR = "#777777"
export const LINK_COLOR = "#64A2FF"
export const PRIMARY_COLOR = "#F15F4B"
export const SECONDARY_COLOR = "#BDBDBD"
export const SUCCESS_COLOR = "#219653"
export const TEXT_DARK_COLOR = "#333"
export const TEXT_LIGHT_COLOR = "#999"
export const WARNING_COLOR = "#F0AD4E"
