import React from "react"
import { Helmet } from "react-helmet"
import { BrowserRouter } from "react-router-dom"

import { BookingContainer } from "./components/BookingContainer"
import { BookingContextProvider } from "./components/BookingContext"

export const BookingRoot: React.FunctionComponent = () => (
  <>
    <Helmet>
      <title>Booking</title>
    </Helmet>
    <BrowserRouter>
      <BookingContextProvider>
        <BookingContainer />
      </BookingContextProvider>
    </BrowserRouter>
  </>
)
