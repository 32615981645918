import React from "react"
import styled from "styled-components"

import { CompletedIcon } from "../../../../components/Icon"
import { PageContainer } from "../../../../components/Page"
import { SUCCESS_COLOR } from "../../../../helpers/styles"
import { usePaymentState } from "../../components/PaymentContext"
import {
  PaymentPageContactDescription,
  PaymentPageDescription,
  PaymentPageHeadline,
  PaymentPagePayeeName,
  PaymentPageTitle,
} from "../../components/PaymentPage"

const StyledTitle = styled( PaymentPageTitle )`
  color: ${ SUCCESS_COLOR };
`

export const CompletedPage: React.FunctionComponent = () => {
  const { paymentSummary } = usePaymentState()

  if( ! paymentSummary ) {
    return null
  }

  return (
    <PageContainer showPoweredBy={ true }>
      <PaymentPageHeadline>Thank you!</PaymentPageHeadline>
      <PaymentPageDescription>Your payment for <PaymentPagePayeeName>{ paymentSummary.name }</PaymentPagePayeeName> has been received.</PaymentPageDescription>
      <CompletedIcon/>
      <StyledTitle>Payment Successful!</StyledTitle>
      <PaymentPageContactDescription>Please contact { paymentSummary.name } for any questions</PaymentPageContactDescription>
    </PageContainer>
  )
}
