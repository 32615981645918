import React, { useCallback, useState } from "react"
import { matchPath } from "react-router"
import { useLocation } from "react-router-dom"
import { animated, useTransition } from "react-spring"
import { useEffectOnce, useMountedState, usePrevious } from "react-use"
import styled from "styled-components"

import { ErrorPage } from "../../../components/ErrorPage"
import { FullPageContainer, PageStepContainer } from "../../../components/Page"
import { Spinner } from "../../../components/Spinner"
import { getVisitCheckInSettings, getVisitCheckInSummary } from "../../../helpers/api"
import { logError } from "../../../helpers/error"
import { CompletedPage } from "../pages/CompletedPage/CompletedPage"
import { InformationPage } from "../pages/InformationPage/InformationPage"
import { LocationPage } from "../pages/LocationPage/LocationPage"
import { PhoneNumberPage } from "../pages/PhoneNumberPage/PhoneNumberPage"
import { PhoneNumberVerificationPage } from "../pages/PhoneNumberVerificationPage/PhoneNumberVerificationPage"
import { WelcomePage } from "../pages/WelcomePage/WelcomePage"
import { CheckInStep, VisitStatusType } from "../types"
import { useCheckInState } from "./CheckInContext"

const StyledLoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const stepsOrder = [
  CheckInStep.WELCOME,
  CheckInStep.PHONE_NUMBER,
  CheckInStep.PHONE_NUMBER_VERIFICATION,
  CheckInStep.LOCATION,
  CheckInStep.INFORMATION,
  CheckInStep.COMPLETED,
]

export const CheckInContainer: React.FunctionComponent = () => {
  const { step, setSettings, setStep, setVisit } = useCheckInState()
  const isMounted = useMountedState()
  const previousStep = usePrevious( step )
  const location = useLocation()
  const [ isLoading, setIsLoading ] = useState( true )

  const currentStepIndex = step ? stepsOrder.indexOf( step ) : 0
  const previousStepIndex = previousStep ? stepsOrder.indexOf( previousStep ) : -1

  const transitions = useTransition(
    step,
    {
      enter: { opacity: 1, transform: "translate(0%,0)" },
      from: { opacity: 0, transform: currentStepIndex > previousStepIndex ? "translate(100%,0)" : "translate(-100%,0)" },
      immediate: ! isMounted() || ! previousStep,
      key: step,
      leave: { opacity: 0, transform: currentStepIndex > previousStepIndex ? "translate(-50%,0)" : "translate(50%,0)" },
    },
  )

  useEffectOnce( () => {
    const urlParams = new URLSearchParams( location.search )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const routes: Array<{ path: string, handler( params: any ): void }> = [
      {
        path: "/check-in",
        handler() {
          const visitId = urlParams.get( "visit_id" )
          const token = urlParams.get( "token" )

          if( ! visitId || ! token ) {
            setStep( CheckInStep.ERROR )
            return
          }

          getVisitCheckInSummary( visitId!, token! ).then(
            ( inviteSummary ) => {
              setSettings( inviteSummary.settings )
              setVisit( inviteSummary.visit )
              if( inviteSummary.visit.status.type === VisitStatusType.INVITED ) {
                setStep( CheckInStep.WELCOME )
              } else {
                setStep( CheckInStep.COMPLETED )
              }
              setIsLoading( false )
            },
            ( error ) => {
              setIsLoading( false )
              logError( error )
              setStep( CheckInStep.ERROR )
            },
          )
        },
      },
      {
        path: "/share/:slug",
        handler( params: { slug: string } ) {
          getVisitCheckInSettings( params.slug ).then(
            ( settings ) => {
              settings.preview = ( urlParams.get( "preview" ) === "true" )

              setSettings( settings )
              setStep( CheckInStep.WELCOME )
              setIsLoading( false )
            },
            ( error ) => {
              setIsLoading( false )
              logError( error )
              setStep( CheckInStep.ERROR )
            },
          )
        },
      },
    ]

    for( const route of routes ) {
      const match = matchPath( route.path, location.pathname )

      if( match ) {
        route.handler( match.params )
        break
      }
    }
  } )

  const renderStep = useCallback( ( currentStep: CheckInStep ) => {
    switch( currentStep ) {
      case CheckInStep.WELCOME:
        return <WelcomePage />
      case CheckInStep.LOCATION:
        return <LocationPage />
      case CheckInStep.PHONE_NUMBER:
        return <PhoneNumberPage />
      case CheckInStep.PHONE_NUMBER_VERIFICATION:
        return <PhoneNumberVerificationPage />
      case CheckInStep.INFORMATION:
        return <InformationPage />
      case CheckInStep.COMPLETED:
        return <CompletedPage />
      case CheckInStep.ERROR:
        return <ErrorPage />
      default:
    }
  }, [] )

  return (
    <FullPageContainer>
      {
        isLoading
          ? (
            <StyledLoadingContainer>
              <Spinner />
            </StyledLoadingContainer>
          )
          : (
            transitions( ( style, item ) => {
              return (
                <animated.div
                  key={ item }
                  style={ {
                    ...style,
                    background: "white",
                    flex: 1,
                    height: "100%",
                    position: "absolute",
                    width: "100%",
                  } }
                >
                  <PageStepContainer>
                    { renderStep( item! ) }
                  </PageStepContainer>
                </animated.div>
              )
            } )
          )
      }
    </FullPageContainer>
  )
}
