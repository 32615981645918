import React, { useCallback, useRef, useState } from "react"
import { animated, useSpring, useTransition } from "react-spring"
import { useMountedState, usePrevious } from "react-use"
import styled from "styled-components"

import { PrimaryButton } from "../../../../components/Button"
import type { VisitCheckInModel } from "../../../../helpers/api"
import { createVisit, sendVisitCheckIn } from "../../../../helpers/api"
import { logError } from "../../../../helpers/error"
import { useCheckInState } from "../../components/CheckInContext"
import type { FieldAnswer, FieldAnswers } from "../../types"
import { CheckInStep } from "../../types"
import type { InformationFieldRef } from "./InformationField"
import { InformationField } from "./InformationField"
import { InformationHeader } from "./InformationHeader"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledInformationHeaderContainer = styled( animated.div )`
  flex: none;
  margin: 15px 25px 25px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const StyledFooter = styled.div`
  align-items: center;
  background: white;
  box-shadow: 0px -4px 5px white;
  display: flex;
  flex: none;
  height: 75px;
  justify-content: center;
  padding: 0 25px;
`

const StyledFooterCTA = styled( PrimaryButton )`
  flex: 1;
`

const FieldWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`

const FieldContainer = styled( animated.div )`
  bottom: 0;
  left: 0;
  overflow: scroll;
  padding: 10px 30px 25px 30px;
  position: absolute;
  right: 0;
  top: 0;
`

export interface InformationPageProps {

}

export const InformationPage: React.FunctionComponent<InformationPageProps> = () => {
  const { model, setModel, settings, setStep, visit } = useCheckInState()
  const { fields } = settings!
  const [ fieldIndex, setFieldIndex ] = useState( 0 )
  const previousFieldIndex = usePrevious( fieldIndex )
  const isMounted = useMountedState()
  const informationFieldRef = useRef<InformationFieldRef>( null )
  const [ isProcessing, setIsProcessing ] = useState<boolean>( false )
  const [ isValid, setIsValid ] = useState<boolean>( false )
  const [ isFocusMode, setIsFocusMode ] = useState<boolean>( false )

  const next = useCallback( () => {
    if( ! informationFieldRef.current || isProcessing ) {
      return
    }

    informationFieldRef.current.submit()
  }, [ isProcessing ] )

  const onCompletedHandler = useCallback( ( answer: FieldAnswer ) => {
    if( isProcessing ) {
      return
    }
    const field = fields[ fieldIndex ]
    const updatedAnswers: FieldAnswers = {
      ...model.answers,
      [ field.id ]: answer,
    }

    setModel( { ...model, answers: updatedAnswers } )

    if( fieldIndex + 1 >= fields.length ) {
      if( settings!.preview ) {
        setStep( CheckInStep.COMPLETED )
        return
      }

      const { location_status } = model

      setIsProcessing( true )

      if( visit ) {
        const checkInModel: VisitCheckInModel = {
          answers: updatedAnswers,
          ...( location_status && { location_status } ),
        }

        sendVisitCheckIn( visit.id, visit.auth_token, checkInModel )
          .then(
            () => {
              setStep( CheckInStep.COMPLETED )
            },
            ( error ) => {
              setIsProcessing( false )
              logError( error )
            },
          )
      } else {
        createVisit( settings!.merchant.slug, {
          answers: updatedAnswers,
          ...( location_status && { location_status } ),
          phoneNumber: model.phoneNumber!,
          phoneNumberCode: model.phoneNumberVerificationCode!,
        } )
          .then(
            () => {
              setStep( CheckInStep.COMPLETED )
            },
            ( error ) => {
              setIsProcessing( false )
              logError( error )
            },
          )
      }
    } else {
      setIsFocusMode( false )
      setFieldIndex( fieldIndex + 1 )
    }
  }, [ fieldIndex, fields, isProcessing, model, setModel, setStep, settings, visit ] )

  const onValidityChangedHandler = ( newIsValid: boolean ) => {
    setIsValid( newIsValid )
  }

  const onIsFocusModeChangedHandler = ( newIsFocusMode: boolean ) => {
    setIsFocusMode( newIsFocusMode )
  }

  const backHandler = () => {
    if( fieldIndex === 0 ) {
      return
    }

    setFieldIndex( fieldIndex - 1 )
  }

  const nextHandler = useCallback( () => {
    if( isProcessing ) {
      return
    }
    next()
  }, [ isProcessing, next ] )

  const isGoingNext = ! previousFieldIndex || previousFieldIndex < fieldIndex

  const questionTransitions = useTransition(
    fieldIndex,
    {
      enter: { opacity: 1, transform: "translate(0%,0)" },
      from: isGoingNext ? { opacity: 0, transform: "translate(100%,0)" } : { opacity: 0, transform: "translate(-50%,0)" },
      immediate: ! isMounted(),
      key: fieldIndex,
      leave: isGoingNext ? { opacity: 0, transform: "translate(-50%,0)" } : { opacity: 0, transform: "translate(100%,0)" },
    },
  )

  const progressBarSpringStyle = useSpring( {
    delay: 600,
    from: { opacity: 0 },
    to: { opacity: 1 },
  } )

  if( ! settings ) {
    return null
  }

  return (
    <Wrapper>
      <Container>
        <StyledInformationHeaderContainer style={ progressBarSpringStyle }>
          <InformationHeader
            onBackClicked={ backHandler }
            progress={ ( fieldIndex / fields.length ) * 100 }
            showBackButton={ fieldIndex > 0 }
          />
        </StyledInformationHeaderContainer>
        <FieldWrapper>
          {
            questionTransitions( ( style, item ) => {
              const field = JSON.parse( JSON.stringify( fields[ item ] ) )
              const initialAnswer = model.answers?.[ field.id ] ? model.answers[ field.id ] : null

              return (
                <FieldContainer key={ item } style={ style }>
                  <InformationField
                    fieldSettings={ field }
                    initialAnswer={ initialAnswer }
                    onCompleted={ onCompletedHandler }
                    ref={ informationFieldRef }
                    onValidityChanged={ onValidityChangedHandler }
                    onIsFocusModeChanged={ onIsFocusModeChangedHandler }
                  />
                </FieldContainer>
              )
            } )
          }
        </FieldWrapper>
      </Container>
      {
        ! isFocusMode && (
          <StyledFooter>
            <StyledFooterCTA onClick={ nextHandler } disabled={ ( ! settings.preview && ! isValid ) || isProcessing }>{ fieldIndex + 1 >= fields.length ? "Submit" : "Next" }</StyledFooterCTA>
          </StyledFooter>
        )
      }
    </Wrapper>
  )
}
