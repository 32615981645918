import React from "react"
import styled from "styled-components"

import { PoweredByOneLocal } from "../../../components/PoweredByOneLocal"
import { LARGE_SCREEN_MEDIA_QUERY } from "../../../helpers/responsive"
import { useBookingState } from "./BookingContext"

export interface BookingFooterProps {
  buttonText: string
  disabled: boolean

  onButtonClick?(): void
}

const BookingFooterContainer = styled.div`
  align-items: center;
  background: #ffffff;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  left: 0;
  padding: 10px 30px;
  position: fixed;
  width: calc( 100% - 60px );

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    bottom: 10px;
    flex-direction: row;
    left: 0;
    margin: 10px 0;
    padding: 0 40px;
    position: absolute;
    right: 0;
  }
`
const BookingFooterContainerNextButton = styled.button`
  background: ${ props => props.theme.cta.background };
  border: none;
  border-radius: 6px;
  color: ${ props => props.theme.cta.text };
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 45px;
  outline: none;
  width: 100%;

  &:disabled {
    opacity: 0.6;
  }

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    float: right;
    width: 222px;
  }
`

const BookingFooterContainerNextButtonContainer = styled.div`
  flex: 1;
  margin-bottom: 20px;
  width: 100%;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    margin-bottom: 0;
    margin-left: auto;
  }
`

const BookingFooterContainerPoweredByOneLocalContainer = styled.div`
  flex: 1;
`

export const BookingFooter: React.FunctionComponent<BookingFooterProps> = ( { buttonText, disabled, onButtonClick } ) => {
  const { settings } = useBookingState()

  if( ! settings ) {
    return null
  }

  return (
    <BookingFooterContainer>
      { settings.design.powered_by?.enabled && (
        <BookingFooterContainerPoweredByOneLocalContainer>
          <PoweredByOneLocal />
        </BookingFooterContainerPoweredByOneLocalContainer>
      )
      }
      <BookingFooterContainerNextButtonContainer>
        <BookingFooterContainerNextButton type={ onButtonClick ? "button" : "submit" } disabled={ disabled } onClick={ onButtonClick }>
          { buttonText }
        </BookingFooterContainerNextButton>
      </BookingFooterContainerNextButtonContainer>
    </BookingFooterContainer>
  )
}
