export interface PaymentSummary {
  name: string
  stripe: {
    checkout_session_id?: string
    connected_account_id: string
  }
  status: {
    type: PaymentStatusType
  }
}

export enum PaymentStep {
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  ERROR = "error",
  EXPIRED = "expired",
  FAILED = "failed",
  PENDING = "pending",
}

export enum PaymentStatusType {
  AUTHORIZED = "authorized",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  EXPIRED = "expired",
  FAILED = "failed",
  PENDING = "pending",
  REFUNDED = "refunded",
}
