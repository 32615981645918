import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { usePrevious } from "react-use"
import styled from "styled-components"

import { ERROR_COLOR } from "../../../../helpers/styles"
import { useCheckInState } from "../../components/CheckInContext"
import type { CheckInSettingsFieldMultipleChoice, FieldAnswerMultipleChoice } from "../../types"
import { FIELD_OTHER_OPTION_ID } from "../../types"
import { FieldLabel } from "./FieldLabel"

const StyledFieldHeader = styled.div`
  margin-bottom: 15px;
`

const StyledFieldLabel = styled( FieldLabel )`
  margin-bottom: 5px;
`

const StyledError = styled.div`
  color: ${ ERROR_COLOR };
  font-size: 16px;
  margin-top: 5px;
`

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex: none;
  margin-right: 10px;
`

interface CheckboxProps {
  isChecked: boolean
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ( { isChecked } ) => {
  return (
    <StyledCheckboxContainer>
      {
        isChecked
          ? (
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="24" height="24" rx="2.5" fill="#FFF1F0" stroke="#F15F4B"/>
              <path d="M3 3V22.5H22.5V3H3ZM10.9688 17.6428L7.19062 13.4442L8.30578 12.4406L10.9308 15.3572L17.1562 7.94344L18.3066 8.90625L10.9688 17.6428Z" fill="#F15F4B"/>
            </svg>
          )
          : (
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="25" height="25" rx="3" fill="#E0E0E0"/>
            </svg>
          )
      }
    </StyledCheckboxContainer>
  )
}

const StyledOptionsContainer = styled.div`
  overflow: scroll;
`

interface StyledOptionProps {
  isSelected?: boolean
 }

const StyledOption = styled.div<StyledOptionProps>`
  border: 1px solid black;

  padding: 25px 20px;
  border-radius: 5px;

  transition: background-color 0.3s ease, border 0.3s ease;

  ${ ( props ) =>
    props.isSelected
      ? `
      background-color: #FFF1F0;
      border: 1px solid #F15F4B;
    `
      : `
      background-color: #F2F2F2;
      border: 1px solid #E0E0E0;
    `
}

  & + & {
    margin-top: 5px;
  }

`

const StyledOptionContent = styled.div`
  align-items: center;
  display: flex;
`

const StyledOptionLabel = styled.div`
  flex: 1;
  margin-left: 10px;
  font-size: 18px;
`

const StyledOtherTextarea = styled.textarea`
  display: block;
  border: 1px solid #F15F4B;
  border-radius: 2px;
  margin-top: 10px;
  font-size: 16px;
  padding: 5px 10px;
  height: 50px;
  width: calc( 100% - 20px );
  resize: none;
`

export interface InformationFieldMultiChoiceProps {
  fieldSettings: CheckInSettingsFieldMultipleChoice
  initialAnswer: FieldAnswerMultipleChoice | null
  onCompleted( answer: FieldAnswerMultipleChoice | null ): void
  onValidityChanged( isValid: boolean ): void
}

export interface InformationFieldMultiChoiceRef {
  submit(): void
}

export const InformationFieldMultiChoice = React.forwardRef<InformationFieldMultiChoiceRef, InformationFieldMultiChoiceProps>( ( { fieldSettings, initialAnswer, onCompleted, onValidityChanged }, ref ) => {
  const inputOtherTextRef = useRef<HTMLTextAreaElement | null>( null )
  const formRef = useRef<HTMLFormElement>( null )
  const { errors, formState, getValues, handleSubmit, register, setValue } = useForm( { mode: "onChange" } )
  const [ selectedOptions, setSelectedOptions ] = useState<string[]>( initialAnswer?.option_ids || [] )
  const [ errorText, setErrorText ] = useState<string | null>( null )
  const [ isValid, setIsValid ] = useState<boolean>( false )
  const previousFormIsValid = usePrevious( isValid )
  const previousSelectedOptions = usePrevious( selectedOptions )
  const { settings } = useCheckInState()

  const triggerFormSubmit = () => {
    // Cancellable need to explicitly set to true to allow preventDefault in firefox otherwise form resets
    if( formRef.current ) {
      formRef.current.dispatchEvent( new Event( "submit", { bubbles: true, cancelable: true } ) )
    }
  }

  useImperativeHandle( ref, () => ( {
    submit: () => {
      triggerFormSubmit()
    },
  } ) )

  useEffect( () => {
    if( initialAnswer ) {
      const initialSelectedOptions = [ ...initialAnswer.option_ids ]
      if( initialAnswer.other ) {
        initialSelectedOptions.push( FIELD_OTHER_OPTION_ID )
      }

      setSelectedOptions( initialSelectedOptions )

      setTimeout( () => {
        setValue( "other", initialAnswer.other )
      } )
    }
  }, [ initialAnswer, setValue ] )

  useEffect( () => {
    updateValidity()
  } )

  useEffect( () => {
    // If the value changed and the selected value is not other
    if(
      fieldSettings.allow_multiple_answers
      || ! selectedOptions
      || selectedOptions.length !== 1
      || selectedOptions[ 0 ] === FIELD_OTHER_OPTION_ID
    ) {
      return
    }

    // Ignore on first render
    if( previousSelectedOptions == null ) {
      return
    }

    // Ensure the value is different, this is needed because during the submission
    // the props are set with the same value
    if( previousSelectedOptions.length === 1 && previousSelectedOptions[ 0 ] === selectedOptions[ 0 ] ) {
      return
    }

    triggerFormSubmit()

  }, [ fieldSettings, previousSelectedOptions, selectedOptions ] )

  const updateValidity = () => {
    const { other } = getValues()
    let updatedIsValid = true
    setErrorText( null )

    if( ! settings!.preview && selectedOptions.includes( FIELD_OTHER_OPTION_ID ) && ( other == null || other.length === 0 ) ) {
      if( formState.isSubmitted ) {
        setErrorText( "Please fill the \"other\" field." )
      }
      updatedIsValid = false
    } else if( ! settings!.preview && fieldSettings.is_required && selectedOptions.length === 0 ) {
      if( formState.isSubmitted ) {
        setErrorText( "Please select at least one option." )
      }
      updatedIsValid = false
    }

    setIsValid( updatedIsValid )

    if( updatedIsValid ) {
      setErrorText( null )
    }

    if( updatedIsValid !== previousFormIsValid && onValidityChanged ) {
      onValidityChanged( isValid )
    }

    return formState.isValid
  }

  const submitHandler = handleSubmit( ( { other } ) => {
    const answer: FieldAnswerMultipleChoice = {
      option_ids: selectedOptions.filter( ( option ) => option !== FIELD_OTHER_OPTION_ID ),
    }

    if( other ) {
      answer.other = other
    }

    if( onCompleted ) {
      onCompleted( answer )
    }
  } )

  const toggleOption = ( e: React.MouseEvent<HTMLDivElement, MouseEvent>, optionId: string ) => {
    if( inputOtherTextRef.current && inputOtherTextRef.current === e.target ) {
      return
    }

    if( fieldSettings.allow_multiple_answers ) {
      const newSelectedOptions = [ ...selectedOptions ]
      const optionIndex = selectedOptions.indexOf( optionId )

      if( optionIndex !== -1 ) {
        newSelectedOptions.splice( optionIndex, 1 )

        if( optionId === FIELD_OTHER_OPTION_ID ) {
          setValue( "other", null )
        }
      } else {
        newSelectedOptions.push( optionId )
      }

      setSelectedOptions( newSelectedOptions )
    } else {
      if( selectedOptions.length === 1 && selectedOptions[ 0 ] === optionId ) {
        return
      }

      setSelectedOptions( [ optionId ] )
    }
  }

  const isOtherChecked = selectedOptions.includes( FIELD_OTHER_OPTION_ID )

  return (
    <form onSubmit={ submitHandler } ref={ formRef } noValidate={ true }>
      <StyledFieldHeader>
        <StyledFieldLabel fieldSettings={ fieldSettings } />
        {
          fieldSettings.allow_multiple_answers && (
            <div>Select all that apply</div>
          )
        }
        {
          errorText && (
            <StyledError>{ errorText }</StyledError>
          )
        }
      </StyledFieldHeader>
      <StyledOptionsContainer>
        {
          fieldSettings.options.map( ( option ) => {
            const isChecked = selectedOptions.includes( option.id )

            return (
              <StyledOption key={ option.id } isSelected={ isChecked } onClick={ ( e ) => { toggleOption( e, option.id ) } }>
                <StyledOptionContent>
                  <Checkbox isChecked={ isChecked } />
                  <StyledOptionLabel>{ option.label }</StyledOptionLabel>
                </StyledOptionContent>
              </StyledOption>
            )
          } )
        }
        {
          fieldSettings.include_other_choice && (
            <>
              <StyledOption isSelected={ isOtherChecked } onClick={ ( e ) => toggleOption( e, FIELD_OTHER_OPTION_ID ) }>
                <StyledOptionContent>
                  <Checkbox isChecked={ isOtherChecked } />
                  <StyledOptionLabel>Other</StyledOptionLabel>
                </StyledOptionContent>
                {
                  isOtherChecked && (
                    <>
                      <StyledOtherTextarea
                        name="other"
                        ref={ ( instance ) => {
                          if( instance ) {
                            register( instance, { required: ! settings!.preview } )
                          }
                          inputOtherTextRef.current = instance
                        } }
                      />
                      { errors.other?.type === "required" && <StyledError>This field is required</StyledError> }
                    </>
                  )
                }

              </StyledOption>
            </>
          )
        }
      </StyledOptionsContainer>
    </form>
  )
} )
