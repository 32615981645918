
import React from "react"

import { PoweredByOneLocalIcon } from "./Icon"

export interface PoweredByOneLocalProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  footerMode?: boolean
}

export const PoweredByOneLocal: React.FunctionComponent<PoweredByOneLocalProps> = ( { footerMode, style, ...props } ) => {
  if( footerMode ) {
    style = {
      ...style,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      left: 0,
      paddingBottom: "25px",
      position: "absolute",
      right: 0,
    }
  }

  return (
    <div style={ style } { ...props }>
      { /* eslint-disable-next-line react/jsx-no-target-blank */ }
      <a href="https://onelocal.com" target="_blank"><PoweredByOneLocalIcon /></a>
    </div>
  )
}
