import React, { createContext, useContext, useState } from "react"

import type { CheckInSettings, CheckInStep, FieldAnswers, LocationStatus, Visit } from "../types"

interface CheckInContextType {
  model: CheckInContextModel
  settings: CheckInSettings | null
  step: CheckInStep | null
  visit: Visit | null

  setModel( model: CheckInContextModel ): void
  setVisit( visit: Visit | null ): void
  setSettings( settings: CheckInSettings | null ): void
  setStep( step: CheckInStep ): void
}

export interface CheckInContextModel {
  answers: FieldAnswers
  location_status?: LocationStatus
  phoneNumber?: string
  phoneNumberVerificationCode?: string
}

export const CheckInContext = createContext<CheckInContextType>( null! )

// eslint-disable-next-line @typescript-eslint/ban-types
export const CheckInContextProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ( props ) => {
  const [ model, setModel ] = useState<CheckInContextModel>( { answers: {} } )
  const [ settings, setSettings ] = useState<CheckInSettings | null>( null )
  const [ visit, setVisit ] = useState<Visit | null>( null )
  const [ step, setStep ] = useState<CheckInStep | null>( null )

  const contextValue: CheckInContextType = {
    model,
    settings,
    step,
    visit,
    setModel,
    setSettings,
    setStep,
    setVisit,
  }

  return <CheckInContext.Provider value={ { ...contextValue } }>{ props.children }</CheckInContext.Provider>
}

export function useCheckInState(): CheckInContextType {
  const context = useContext( CheckInContext )
  if( ! context ) {
    throw new Error( "useCheckInState must be used within the CheckInContextProvider" )
  }
  return context
}
