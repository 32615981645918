import React from "react"
import styled from "styled-components"

import { RichText } from "../../../../components/RichText"
import { FONT_WEIGHT_MEDIUM } from "../../../../helpers/styles"
import type { CheckInSettingsField } from "../../types"

const StyledLabel = styled.label`
  display: block;
  font-size: 18px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  margin-bottom: 10px;
`

const StyledLabelText = styled( RichText )`
  display: inline;
`

const StyledLabelRequired = styled.span`
  color: red;
  margin-left: 5px;
`

export interface FieldLabelProps {
  fieldSettings: CheckInSettingsField
}

export const FieldLabel: React.FunctionComponent<FieldLabelProps> = React.memo( ( { fieldSettings } ) => (
  <StyledLabel>
    <StyledLabelText text={ fieldSettings.label } />
    {
      fieldSettings.is_required && (
        <StyledLabelRequired>*</StyledLabelRequired>
      )
    }
  </StyledLabel>
) )
