import React, { useCallback, useState } from "react"
import { useTransition } from "react-spring"
import { useMountedState } from "react-use"
import styled from "styled-components"

import { PrimaryButton } from "../../../../components/Button"
import { PageContainer, PageDescription, PageTitle } from "../../../../components/Page"
import { Spinner } from "../../../../components/Spinner"
import { getDistanceBetweenCoordinates } from "../../../../helpers/geo"
import { FONT_WEIGHT_MEDIUM, PRIMARY_COLOR } from "../../../../helpers/styles"
import { useCheckInState } from "../../components/CheckInContext"
import { CheckInStep, LocationStatus } from "../../types"

const StyledIcon = styled.svg`
  margin-bottom: 45px;
`

const StyledSpinner = styled( Spinner )`
  margin-bottom: 25px;
`

const StyledLoadingText = styled.div`
  color: ${ PRIMARY_COLOR };
  font-size: 18px;
  font-weight: ${ FONT_WEIGHT_MEDIUM };
  text-align: center;
`

const StyledSkipButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 15px;
  position: absolute;
  right: 30px;
  top: 30px;

  :focus {
    outline: 0;
  }
`

const StyledCTA = styled( PrimaryButton )`
  width: 100%;
`

enum LocationPageViewState {
  INTRO = "intro",
  LOADING = "loading",
  ERROR = "error",
}

export interface LocationPageProps {

}

export interface LocationPageState {
  geolocation: {
    latitude: number | null
    longitude: number | null
    error?: Error | GeolocationPositionError
  }
}

export const LocationPage: React.FunctionComponent<LocationPageProps> = () => {
  const { model, settings, setModel, setStep } = useCheckInState()
  const [ error, setError ] = useState<string | null>( null )
  const [ viewState, setViewState ] = useState<LocationPageViewState>( LocationPageViewState.INTRO )
  const isMounted = useMountedState()

  const stateTransitions = useTransition(
    viewState,
    {
      enter: { opacity: 1 },
      from: { opacity: 0 },
      immediate: ! isMounted(),
      keys: viewState,
      leave: { opacity: 0 },
    },
  )

  const getLocation = useCallback( () => {
    setViewState( LocationPageViewState.LOADING )
    navigator.geolocation.getCurrentPosition(
      ( position ) => {
        const distance = getDistanceBetweenCoordinates(
          position.coords.latitude,
          position.coords.longitude,
          settings!.location.coordinate.lat,
          settings!.location.coordinate.lng,
        )

        if( distance > settings!.location.max_distance_meters ) {
          setError( "<strong>Sorry</strong>, you need to be closer to our location in order to start the check-in registration" )
          setViewState( LocationPageViewState.ERROR )
          setModel( { ...model, location_status: LocationStatus.OUT_OF_RANGE } )
          return
        }

        setModel( { ...model, location_status: LocationStatus.WITHIN_RANGE } )
        setStep( CheckInStep.INFORMATION )
      },
      () => {
        setError( "<strong>Sorry</strong>, you need to enable your location to continue with your check-in registration, please enable your location services and try again." )
        setViewState( LocationPageViewState.ERROR )
        setModel( { ...model, location_status: LocationStatus.NOT_DETECTED } )
      },
      {

      },
    )
  }, [ model, settings, setModel, setStep ] )

  const skipHandler = useCallback( () => {
    setStep( CheckInStep.INFORMATION )
  }, [ setStep ] )

  if( ! settings ) {
    return null
  }

  return (
    <div>
      {
        stateTransitions( ( style, item ) => {
          switch( item ) {
            case LocationPageViewState.INTRO:
              return (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                <PageContainer style={ style as any } key={ item }>
                  <PageTitle>Before you start,</PageTitle>
                  <StyledIcon width="118" height="119" viewBox="0 0 118 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.681798 40.2056L56.5637 61.0198C56.8305 61.1192 57.0423 61.3276 57.146 61.5928L79.3339 118.343C79.6782 119.223 80.9394 119.178 81.2201 118.275L117.425 1.84879C117.666 1.0734 116.931 0.349107 116.16 0.601292L0.720275 38.3179C-0.179404 38.6119 -0.205157 39.8752 0.681798 40.2056Z" fill="#3B99FC"/>
                  </StyledIcon>
                  <PageDescription>Enable your location services so we can begin your check-in process</PageDescription>
                  <StyledCTA onClick={ getLocation }>{ error ? "Refresh" : "Enable Location" }</StyledCTA>
                </PageContainer>
              )
            case LocationPageViewState.LOADING:
              return (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                <PageContainer style={ style as any } key={ item }>
                  <StyledSpinner />
                  <StyledLoadingText>Loading...</StyledLoadingText>
                </PageContainer>
              )
            case LocationPageViewState.ERROR:
              return (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                <PageContainer style={ style as any } key={ item }>
                  {
                    ( settings.preview || ! settings.location.is_required ) && (
                      <StyledSkipButton onClick={ skipHandler }>Skip</StyledSkipButton>
                    )
                  }
                  <StyledIcon width="126" height="111" viewBox="0 0 126 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M123.3 94.6501L72.15 5.8501C70.2 2.5501 66.75 0.600098 63 0.600098C59.25 0.600098 55.8 2.5501 53.85 5.8501L2.25004 95.2501C-0.599955 100.35 0.900045 107.1 7.05004 110.1C8.25004 110.7 9.60004 111 10.95 111H114.75H116.1C118.8 111 121.5 109.65 123 107.4C126 102.9 125.7 98.1001 123.3 94.6501ZM59.55 74.5501L57.15 28.8001C61.65 28.8001 63.15 28.8001 68.85 28.8001L66.15 74.5501H59.55ZM68.25 94.5001C66.9 95.8501 64.95 96.7501 63 96.7501C61.05 96.7501 59.1 96.0001 57.75 94.5001C56.4 93.0001 55.5 91.2001 55.5 89.1001C55.5 87.0001 56.25 85.2001 57.75 83.8501C59.25 82.5001 61.05 81.6001 63 81.6001C64.95 81.6001 66.9 82.3501 68.25 83.8501C69.6 85.2001 70.5 87.1501 70.5 89.1001C70.5 91.2001 69.75 93.1501 68.25 94.5001Z" fill="#EC3939"/>
                  </StyledIcon>
                  <PageDescription dangerouslySetInnerHTML={ { __html: error! } } />
                  <StyledCTA onClick={ getLocation }>Try Again</StyledCTA>
                </PageContainer>
              )
            default:
              return null
          }
        } )
      }
    </div>
  )
}
