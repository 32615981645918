import { TextField } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { withStyles } from "@material-ui/core/styles"
import React, { useRef, useState } from "react"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import type * as OneLocal from "ol-shared"

import { BORDER_COLOR, ERROR_COLOR, TEXT_DARK_COLOR } from "../../../helpers/styles"
import type { BookingFieldMultipleChoice } from "../types"
import { useBookingState } from "./BookingContext"

export interface SelectProps {
  field: BookingFieldMultipleChoice
  formState: {
    isSubmitted: boolean
  }
  name: string

  register( ref: any, validateRule?: any ): void // eslint-disable-line @typescript-eslint/no-explicit-any
  onChange( value: OneLocal.LocalVisits.Types.Booking.AnswerMultipleChoice ): void
}

const SelectAllApplyText = styled.div`
  color: ${ TEXT_DARK_COLOR };
  font-size: 11px;
  margin-bottom: 12px;
`

const SelectContainer = styled.div`
  width: 100%;
`

const SelectErrorText = styled.div`
  color: ${ ERROR_COLOR };
  font-size: 12px;
  margin-top: 5px;
`

const SelectFormControlLabel = withStyles( {
  root: {
    width: "100%",
  },
  label: {
    color: TEXT_DARK_COLOR,
    fontSize: "14px",
  },
} )( FormControlLabel )

const SelectLabel = styled.div`
  color: ${ TEXT_DARK_COLOR };
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
`

const SelectLabelRequired = styled.span`
  margin-left: 5px;
`

const SelectOptionContainer = styled.div`
  width: 100%;
`

const SelectOtherTextField = styled( TextField )`
  display: block;
`

const FIELD_OTHER_OPTION_ID = "other"

export const Select: React.FunctionComponent<SelectProps> = ( { field, formState, name, onChange, register } ) => {
  const { model } = useBookingState()
  const inputOtherOptionTextRef = useRef<HTMLTextAreaElement | null>( null )
  const [ answerModel, setAnswerModel ] = useState<OneLocal.LocalVisits.Types.Booking.AnswerMultipleChoice>(
    model.answers?.[ field.id ]
      ? { ...model.answers[ field.id ] as OneLocal.LocalVisits.Types.Booking.AnswerMultipleChoice }
      : { option_ids: [] },
  )

  useEffectOnce( () => {
    register( name, {
      required: field.is_required ? "Please fill out this field" : false,
      validate: ( value: OneLocal.LocalVisits.Types.Booking.AnswerMultipleChoice ) => {
        if( value?.option_ids?.includes( "other" ) && ! value?.other ) {
          return false
        }

        if( ! field.is_required ) {
          return true
        }

        if( ! value.option_ids?.length ) {
          return "Please fill out this field"
        }

        return true
      },
    } )
  } )

  const toggleOption = ( event: React.MouseEvent<HTMLButtonElement, MouseEvent>, optionId: string ) => {
    if( inputOtherOptionTextRef.current && inputOtherOptionTextRef.current === event.target ) {
      return
    }

    let updatedAnswerModel: OneLocal.LocalVisits.Types.Booking.AnswerMultipleChoice

    if( field.allow_multiple_answers ) {
      const newSelectedOptionIds = [ ...answerModel.option_ids ]
      const optionIndex = answerModel.option_ids.indexOf( optionId )

      if( optionIndex !== -1 ) {
        newSelectedOptionIds.splice( optionIndex, 1 )
      } else {
        newSelectedOptionIds.push( optionId )
      }

      updatedAnswerModel = {
        ...answerModel,
        option_ids: newSelectedOptionIds,
      }
    } else {
      if( answerModel.option_ids.length === 1 && answerModel.option_ids[ 0 ] === optionId ) {
        return
      }

      updatedAnswerModel = {
        option_ids: [ optionId ],
      }
    }

    setAnswerModel( updatedAnswerModel )
    onChange( updatedAnswerModel )
  }

  const onTextFieldChange = ( event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const updatedAnswerModel: OneLocal.LocalVisits.Types.Booking.AnswerMultipleChoice = {
      ...answerModel,
      other: event.target.value,
    }
    setAnswerModel( updatedAnswerModel )
    onChange( updatedAnswerModel )
  }

  const isOtherOptionChecked = answerModel.option_ids.includes( FIELD_OTHER_OPTION_ID )

  return (
    <SelectContainer>
      <SelectLabel>
        { field.label }
        {
          field.is_required
          && <SelectLabelRequired style={ formState.isSubmitted && ! answerModel.option_ids.length ? { color: ERROR_COLOR } : {} }>*</SelectLabelRequired>
        }
        {
          field.allow_multiple_answers
          && <SelectAllApplyText>Select all that apply</SelectAllApplyText>
        }
        {
          field.is_required && formState.isSubmitted && ! answerModel.option_ids.length
          && <SelectErrorText>This is a required field</SelectErrorText>
        }
      </SelectLabel>
      {
        field.options.map( ( option ) => {
          const isOptionChecked = answerModel.option_ids.includes( option.value )

          return (
            <SelectOptionContainer key={ option.value }>
              <SelectFormControlLabel
                control={
                  <Checkbox checked={ isOptionChecked } color="primary" onClick={ ( event ) => { toggleOption( event, option.value ) } } />
                }
                label={ option.label }
              />
            </SelectOptionContainer>
          )
        } )
      }
      {
        field.include_other_choice && (
          <SelectOptionContainer key="other">
            <SelectFormControlLabel
              control={
                <Checkbox checked={ isOtherOptionChecked } color="primary" onClick={ ( event ) => { toggleOption( event, FIELD_OTHER_OPTION_ID ) } } />
              }
              label="Other"
            />
            {
              isOtherOptionChecked && (
                <SelectOtherTextField
                  name={ field.id }
                  id={ field.id }
                  defaultValue={ model.answers?.[ field.id ] ? ( model.answers[ field.id ] as OneLocal.LocalVisits.Types.Booking.AnswerMultipleChoice ).other : null }
                  error={ formState.isSubmitted && isOtherOptionChecked && ! answerModel.other }
                  fullWidth={ true }
                  helperText={ formState.isSubmitted && isOtherOptionChecked && ! answerModel.other ? "This is a required field" : null }
                  inputRef={ ( instance ) => {
                    inputOtherOptionTextRef.current = instance
                  } }
                  multiline={ true }
                  onChange={ onTextFieldChange }
                  rows="5"
                  required={ field.is_required }
                  InputProps={ {
                    disableUnderline: true,
                    style: {
                      border: `1px solid ${ BORDER_COLOR }`,
                      borderRadius: "6px",
                      fontSize: "14px",
                      padding: "10px",
                    },
                  } }
                  InputLabelProps={ {
                    shrink: true,
                    style: {
                      color: TEXT_DARK_COLOR,
                      fontWeight: 500,
                    },
                  } }
                />
              )
            }
          </SelectOptionContainer>
        )
      }
    </SelectContainer>
  )
}
