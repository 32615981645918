import React, { useCallback, useState } from "react"
import styled from "styled-components"

export interface ViewMoreProps {
  limit?: number
  linkColor?: string
  linkLessText?: string
  linkMoreText?: string
  text: string
}

const StyledLink = styled.a`
  font-weight: 500;
  margin-left: 5px;
`

export const ViewMore: React.FunctionComponent<ViewMoreProps> = ( { text, limit, linkColor, linkLessText, linkMoreText } ) => {
  limit = limit || 100
  linkLessText = linkLessText || "Less info"
  linkMoreText = linkMoreText || "More info"

  const [ viewMore, setViewMore ] = useState<boolean>( false )

  const lessText = text.substr( 0, limit )

  const onViewMoreHandler = useCallback( ( event: React.UIEvent<HTMLElement> ) => {
    event.stopPropagation()
    event.preventDefault()
    setViewMore( ! viewMore )
  }, [ viewMore ] )

  const style: React.CSSProperties = {}

  if( linkColor ) {
    style.color = linkColor
  }

  return (
    text.length > limit
      ? (
        <div>
          <span>{ viewMore ? text : `${ lessText }…` }</span>
          <StyledLink href="!#" onClick={ onViewMoreHandler } style={ style }>
            { viewMore ? linkLessText : linkMoreText }
          </StyledLink>
        </div>
      )
      : (
        <div>
          <span>{ text }</span>
        </div>
      )
  )
}
