import moment from "moment"
import React from "react"
import styled from "styled-components"

import { CalendarIcon, LocationIcon, ServiceIcon, UserIcon } from "../../../components/Icon"
import { LARGE_SCREEN_MEDIA_QUERY } from "../../../helpers/responsive"
import { roundDecimal } from "../../../helpers/utils"
import { ScheduleType } from "../types"
import { isBasicServiceType, useBookingState } from "./BookingContext"

const BookingSummaryItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  & + & {
    margin-top: 20px;
  }
`

const BookingSummaryItemDetailContainer = styled.div`
  flex: 1;
  font-weight: 500;
  line-height: 1.5;
  margin-left: 18px;
`

const BookingSummaryItemIconContainer = styled.div`
  flex: 0 1 auto;
  height: 24px;
  width: 20px;
`

const BookingSummaryContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  color: ${ props => props.theme.text };
  font-size: 12px;
  padding: 20px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 16px;
  }
`

const BookingSummaryItemDetailSecondaryContainer = styled.div`
  font-size: 11px;
  font-weight: 500;
  margin-top: 8px;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 13px;
  }
`

const BookingSummaryLink = styled.a`
  color: ${ props => props.theme.hyperlink };
`

export const BookingSummary: React.FunctionComponent = () => {
  const DATE_FORMAT = "YYYY-MM-DD"
  const DISPLAY_DATE_FORMAT = "dddd, MMMM D, YYYY"

  const { model, settings } = useBookingState()

  const iconStyle: React.CSSProperties = {
    fill: `${ settings!.design.colors.secondary.background }`,
  }

  const settingsServiceGroup = settings!.service.groups.find( ( group ) => group.id === model.service?.group_id )
  const settingsServiceGroupLabel = settingsServiceGroup ? settingsServiceGroup.label : null
  const settingsServiceType = settingsServiceGroup?.types.find( ( type ) => type.id === model.service?.type_id )
  const settingsServiceTypeLabel = settingsServiceType ? settingsServiceType.label : null
  const settingsServiceTypeCost = settingsServiceType && isBasicServiceType( settingsServiceType ) && settingsServiceType?.cost ? `$${ roundDecimal( settingsServiceType.cost.amount / 100, 2 ) }` : null
  const settingsServiceTypeDuration = settingsServiceType && isBasicServiceType( settingsServiceType ) ? `${ settingsServiceType.duration / 60 } min` : null

  let bookingTime: string | undefined
  let timeRange: string | undefined

  if( model.schedule ) {
    if( model.schedule.type === ScheduleType.ASAP ) {
      bookingTime = "ASAP"
    } else {
      if( isBasicServiceType( settingsServiceType! ) ) {
        bookingTime = `${ moment( model.schedule.date, DATE_FORMAT ).format( DISPLAY_DATE_FORMAT ) } - ${ model.schedule.start }`
      } else {
        bookingTime = `${ moment( model.schedule.date, DATE_FORMAT ).format( DISPLAY_DATE_FORMAT ) }`
        if( model.schedule.start && model.schedule.end ) {
          timeRange = `Between ${ model.schedule.start } - ${ model.schedule.end }`
        }
      }
    }
  }

  return (
    <BookingSummaryContainer>
      {
        model.location?.enabled && (
          <BookingSummaryItemContainer>
            <BookingSummaryItemIconContainer>
              <LocationIcon style={ iconStyle }/>
            </BookingSummaryItemIconContainer>
            <BookingSummaryItemDetailContainer>
              <BookingSummaryLink href={ model.location.url || `https://map.google.com?q=${ model.location.text }` } target="_blank">{ model.location.text }</BookingSummaryLink>
            </BookingSummaryItemDetailContainer>
          </BookingSummaryItemContainer>
        )
      }
      {
        model.service && (
          <BookingSummaryItemContainer>
            <BookingSummaryItemIconContainer>
              <ServiceIcon style={ iconStyle }/>
            </BookingSummaryItemIconContainer>
            <BookingSummaryItemDetailContainer>
              <div>{ settingsServiceGroupLabel } &#8211; { settingsServiceTypeLabel }</div>
              {
                isBasicServiceType( settingsServiceType! ) && (
                  <BookingSummaryItemDetailSecondaryContainer>
                    {
                      settingsServiceTypeCost && (
                        <span>{ settingsServiceTypeCost } &#8226;&nbsp;</span>
                      )
                    }
                    <span>{ settingsServiceTypeDuration }</span>
                  </BookingSummaryItemDetailSecondaryContainer>
                )
              }
            </BookingSummaryItemDetailContainer>
          </BookingSummaryItemContainer>
        )
      }
      {
        model.assignee && (
          <BookingSummaryItemContainer>
            <BookingSummaryItemIconContainer>
              <UserIcon style={ iconStyle }/>
            </BookingSummaryItemIconContainer>
            <BookingSummaryItemDetailContainer>
              <div>
                { model.assignee.name }
                {
                  model.assignee.title && (
                    <span>&nbsp;&#8211; { model.assignee.title }</span>
                  )
                }
              </div>
            </BookingSummaryItemDetailContainer>
          </BookingSummaryItemContainer>
        )
      }
      {
        model.schedule && (
          <BookingSummaryItemContainer>
            <BookingSummaryItemIconContainer>
              <CalendarIcon style={ iconStyle }/>
            </BookingSummaryItemIconContainer>
            <BookingSummaryItemDetailContainer>
              {
                bookingTime && (
                  <div>{ bookingTime }</div>
                )
              }
              {
                timeRange && (
                  <div>{ timeRange }</div>
                )
              }
            </BookingSummaryItemDetailContainer>
          </BookingSummaryItemContainer>
        )
      }
    </BookingSummaryContainer>
  )
}
