import type { LocalVisits } from "ol-shared"

export interface AssigneeOption {
  image_url?: string
  label: string
  title?: string
  value: string | null
}

export interface BookingAssigneeModel {
  id: string | null
  name?: string
  title?: string
}

export type BookingField = BookingFieldBoolean | BookingFieldMultipleChoice | BookingFieldText

export type BookingFieldBoolean = Omit<LocalVisits.Types.MerchantSettings.BookingFieldBoolean, "type"> & {
  type: BookingFieldType.BOOLEAN
}

export type BookingFieldMultipleChoice = Omit<LocalVisits.Types.MerchantSettings.BookingFieldMultipleChoice, "type"> & {
  type: BookingFieldType.MULTIPLE_CHOICE
}

export type BookingFieldText = Omit<LocalVisits.Types.MerchantSettings.BookingFieldText, "input_type" | "type"> & {
  type: BookingFieldType.TEXT
  input_type: BookingFieldTextInputType
}

export type BookingService = Omit<LocalVisits.Types.MerchantSettings.BookingService, "groups"> & {
  groups: ServiceGroup[]
}

export type ServiceGroup = Omit<LocalVisits.Types.Booking.ServiceGroup, "types"> & {
  types: ServiceType[]
}

export type BasicServiceType = Omit<LocalVisits.Types.Booking.BasicServiceType, "type"> & {
  type: BookingServiceType.BASIC
}

export type WindowServiceType = Omit<LocalVisits.Types.Booking.WindowServiceType, "type"> & {
  type: BookingServiceType.WINDOW
}

export type ServiceType = BasicServiceType | WindowServiceType

export type CreateModel = Omit<LocalVisits.Types.CalendarEvent.PublicCreateModel, "schedule"> & {
  schedule: Omit<LocalVisits.Types.CalendarEvent.PublicCreateModel[ "schedule" ], "type"> & {
    type: ScheduleType
  }
}

export enum BookingFieldTextInputType {
  ADDRESS = "address",
  EMAIL = "email",
  NAME = "name",
  NOTE = "note",
  NUMBER = "number",
  PHONE_NUMBER = "phone_number",
  TEXT = "text",
}

export enum BookingFieldType {
  BOOLEAN = "boolean",
  MULTIPLE_CHOICE = "multiple_choice",
  TEXT = "text",
}

export enum BookingServiceType {
  BASIC = "basic",
  WINDOW = "window",
}

export enum ScheduleStep {
  ASAP = "asap",
  SELECT_TIME = "select_time",
}

export enum ScheduleType {
  ANY = "any",
  ASAP = "asap",
  TIME = "time",
}

export enum BookingStep {
  ASSIGNEE = "assignee",
  CREATED = "created",
  CUSTOMER_INFO = "customer_info",
  ERROR = "error",
  SCHEDULE = "schedule",
  SERVICE = "service",
}

export type BookingSettings = Omit<LocalVisits.Types.Booking.PublicSettings, "fields" | "service"> & {
  fields: BookingField[]
  service: BookingService
}

export enum CalendarEventStatusType {
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  CONFIRMED = "confirmed",
  PENDING = "pending",
}

export type CalendarEvent = Omit<LocalVisits.Types.CalendarEvent.PublicCalendarEvent, "status"> & {
  status: {
    at: string
    type: CalendarEventStatusType
  }
}

export const FIELD_OTHER_OPTION_ID = "other"
