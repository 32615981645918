import moment from "moment"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import type { CalendarTileProperties } from "react-calendar"
import Calendar from "react-calendar"
import { useEffectOnce } from "react-use"
import styled from "styled-components"

import type { LocalVisits } from "ol-shared"

import { CalendarIcon, ClockIcon } from "../../../../components/Icon"
import { getVisitBookingsServiceSchedule } from "../../../../helpers/api"
import { logError } from "../../../../helpers/error"
import { isGreaterThanMediumScreen, LARGE_SCREEN_MEDIA_QUERY } from "../../../../helpers/responsive"
import { BookingStepPageContainer, BookingStepPageMainContainer, BookingStepPageMainContentContainer } from "../../components/BookingContainer"
import type { BookingContextModel } from "../../components/BookingContext"
import { isBasicServiceType, isWindowServiceType, useBookingState } from "../../components/BookingContext"
import { BookingFooter } from "../../components/BookingFooter"
import { BookingStep, ScheduleStep, ScheduleType } from "../../types"

export interface ScheduleProps {
}

const ScheduleStyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    flex-direction: row;
  }
`

const ScheduleOptionContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 22px 40px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  & + & {
    margin-top: 20px;
  }

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    width: 100%;

    & + & {
      margin-left: 20px;
      margin-top: 0;
    }
  }
`

const ScheduleOptionText = styled.div`
  color: ${ props => props.theme.text };
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 15px;
`

const ScheduleStyledCalendar = styled( Calendar )`
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:focus {
    background: ${ props => props.theme.secondary.background };
    color: ${ props => props.theme.secondary.text };
  }

  .react-calendar__tile--now abbr {
    border-bottom: 2px solid ${ props => props.theme.secondary.background };
  }

  .react-calendar__tile:enabled:hover {
    color: ${ props => props.theme.text };
  }

  .react-calendar__tile:enabled:hover {
    background: ${ props => props.theme.secondary.background };
    color: ${ props => props.theme.secondary.text };
  }
`

const ScheduleTimes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 21px;
`

const ScheduleTimesContainer = styled.div`
  margin-top: 60px;
`

const ScheduleTimesDateContainer = styled.div`
  border-bottom: 1px solid ${ props => props.theme.border };
  padding-bottom: 8px;
`

const ScheduleTimesDateText = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 18px;
  }
`

const ScheduledTimeNoPreferenceRow = styled.div`
  align-items: center;
  justify-content: center;
  width: 33%;
`

const ScheduleTimesRow = styled.div`
  align-items: center;
  justify-content: center;
`

const ScheduleTimesRowHeader = styled.div`
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 14px;
  }
`

const ScheduleTimeSlotContainer = styled.div`
  margin: 10px;
`

const ScheduleTimeSlot = styled.div`
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
  padding: 15px 0;
  text-align: center;

  @media ${ LARGE_SCREEN_MEDIA_QUERY } {
    font-size: 16px;
  }
`

const ScheduleTimeSlotsContainer = styled.div`
  width: 100%;
`

const ScheduleWindowServiceText = styled.div`
  font-size: 16px;
  font-weight: 500;
`

const ScheduleWindowServiceTextContainer = styled.div`
  margin-top: 15px;
  text-align: center;
`

export const SchedulePage: React.FunctionComponent<ScheduleProps> = () => {
  const { model, scheduleStep, settings, setModel, setScheduleStep, setStep } = useBookingState()
  const timeSlotsContainerRef = useRef<HTMLDivElement | null>( null )
  const [ isDateSelected, setIsDateSelected ] = useState<boolean>( false )
  const [ isTimeSelected, setIsTimeSelected ] = useState<boolean>( false )
  const [ isMobileDevice, setIsMobileDevice ] = useState<boolean>( ! isGreaterThanMediumScreen() )
  const [ serviceDateScheduleSettings, setServiceDateScheduleSettings ] = useState<LocalVisits.Types.Booking.PublicServiceSchedule>( { days: {} } )
  const [ scheduleModel, setScheduleModel ] = useState<BookingContextModel[ "schedule" ] | null>( model.schedule ? { ...model.schedule } : null )

  const iconStyle: React.CSSProperties = {
    fill: `${ settings!.design.colors.secondary.background }`,
    height: "50px",
    width: "50px",
  }
  const minDate = new Date()
  const selectedDateString = scheduleModel?.date ? moment( scheduleModel.date ).format( "YYYY-MM-DD" ) : null
  const selectedServiceType = useMemo( () => {
    const serviceGroup = settings!.service.groups.find( ( group ) => group.id === model.service!.group_id )
    const serviceType = serviceGroup!.types.find( ( type ) => type.id === model.service!.type_id )

    return serviceType
  }, [ model, settings ] )

  useEffect( () => {
    // Run this only when a first time a date is selected and not on page load or whenever a time is selected
    if( timeSlotsContainerRef?.current && isDateSelected && ! isTimeSelected ) {
      timeSlotsContainerRef.current.scrollIntoView( { behavior: "smooth", block: "start" } )
    }
  } )

  useEffectOnce( () => {
    let scheduleSettingsDays: LocalVisits.Types.Booking.PublicServiceSchedule[ "days" ] = {}
    const run = async ( options: { rangeEndDate: string, rangeStartDate: string } ) => {
      const serviceScheduleSettings = await getVisitBookingsServiceSchedule( settings!.merchant.slug, model.service!.group_id!, model.service!.type_id!, { assigneeId: model.assignee?.id, rangeEndDate: options.rangeEndDate, rangeStartDate: options.rangeStartDate } )

      scheduleSettingsDays = {
        ...scheduleSettingsDays,
        ...serviceScheduleSettings.days,
      }
      setServiceDateScheduleSettings( {
        days: scheduleSettingsDays,
      } )
      if( serviceScheduleSettings.next_start_date && serviceScheduleSettings.next_end_date ) {
        run( { rangeEndDate: serviceScheduleSettings.next_end_date, rangeStartDate: serviceScheduleSettings.next_start_date } )
      }
    }

    run( { rangeEndDate: moment().add( 6, "week" ).toISOString(), rangeStartDate: new Date().toISOString() } )
      .catch( ( error ) => {
        setStep( BookingStep.ERROR )
        logError( error )
      } )
  } )

  const dateFormatter = ( date: Date ) => {
    return moment( date ).format( "MMMM D, YYYY" )
  }

  const isNextButtonDisabled = useMemo( () => {
    if( ! scheduleModel ) {
      return true
    }

    switch( scheduleStep ) {
      case ScheduleStep.ASAP: {
        return ! scheduleModel.type
      }
      case ScheduleStep.SELECT_TIME: {
        if( isWindowServiceType( selectedServiceType! ) ) {
          return scheduleModel.type !== ScheduleType.ANY && ( ! scheduleModel.date || ! scheduleModel.start || ! scheduleModel.end )
        }

        return ! scheduleModel.date || ! scheduleModel.start || ! scheduleModel.end
      }
    }

    return false
  }, [ scheduleModel, scheduleStep, selectedServiceType ] )

  const next = useCallback( () => {
    if( scheduleStep === ScheduleStep.ASAP ) {
      if( scheduleModel?.type === ScheduleType.ASAP ) {
        setModel( { ...model, schedule: { ...scheduleModel } } )
        setStep( BookingStep.CUSTOMER_INFO )
      } else {
        setScheduleStep( ScheduleStep.SELECT_TIME )
      }
    } else if( scheduleModel ) {
      setModel( { ...model, schedule: { ...scheduleModel } } )
      setStep( BookingStep.CUSTOMER_INFO )
    }
  }, [ model, scheduleModel, scheduleStep, setScheduleStep, setModel, setStep ] )

  const onDateSelected = useCallback( ( date: Date ) => {
    setIsDateSelected( true )
    setScheduleModel( { date, type: ScheduleType.TIME } )
  }, [ setScheduleModel ] )

  const onScheduleTypeSelected = useCallback( ( type: ScheduleType ) => {
    setScheduleModel( { type } )
  }, [ setScheduleModel ] )

  const onNoPreferenceSelected = useCallback( () => {
    setIsTimeSelected( true )
    if( scheduleModel ) {
      setScheduleModel( { ...scheduleModel, ...{ end: null, start: null, time_slot_id: null, type: ScheduleType.ANY } } )
    }
  }, [ scheduleModel ] )

  const onTimeSelected = useCallback( ( timeSlot: { end: string, start: string }, timeSlotId?: string ) => {
    setIsTimeSelected( true )
    if( scheduleModel ) {
      setScheduleModel( { ...scheduleModel, ...{ time_slot_id: timeSlotId }, ...timeSlot } )
    }
  }, [ scheduleModel ] )

  const resizeHandler = useCallback( () => {
    setIsMobileDevice( ! isGreaterThanMediumScreen() )
  }, [ setIsMobileDevice ] )

  useEffectOnce( () => {
    window.addEventListener( "resize", resizeHandler )

    return () => window.removeEventListener( "resize", resizeHandler )
  } )

  if( ! settings ) {
    return null
  }

  const selectedTimeSlotStyle: React.CSSProperties = {
    background: settings.design.colors.secondary.background,
    border: `1px solid ${ settings.design.colors.secondary.background }`,
    color: settings.design.colors.secondary.text,
    fontWeight: "bold",
  }

  const getTimeSlotStyle = ( startTime: string | null ) => {
    const style: React.CSSProperties = scheduleModel?.start === startTime ? selectedTimeSlotStyle : { border: `1px solid ${ settings.design.colors.border }` }

    if( isBasicServiceType( selectedServiceType! ) && selectedDateString && serviceDateScheduleSettings.days[ selectedDateString ].length === 1 && ! isMobileDevice ) {
      style.width = "50%"
    }

    return style
  }

  const isDateDisabled = ( { date }: CalendarTileProperties ) => {
    const dateString = moment( date ).format( "YYYY-MM-DD" )
    if( serviceDateScheduleSettings.days[ dateString ]?.length ) {
      return false
    }

    return true
  }

  return (
    <BookingStepPageContainer>
      <BookingStepPageMainContainer>
        <BookingStepPageMainContentContainer>
          {
            scheduleStep === ScheduleStep.ASAP
              ? (
                <ScheduleStyledContainer>
                  <ScheduleOptionContainer
                    onClick={ () => onScheduleTypeSelected( ScheduleType.TIME ) }
                    style={ scheduleModel?.type === ScheduleType.TIME || scheduleModel?.type === ScheduleType.ANY ? { border: `2px solid ${ settings!.design.colors.secondary.background }` } : {} }
                  >
                    <CalendarIcon style={ iconStyle } />
                    <ScheduleOptionText>Select a date and time</ScheduleOptionText>
                  </ScheduleOptionContainer>
                  <ScheduleOptionContainer
                    onClick={ () => onScheduleTypeSelected( ScheduleType.ASAP ) }
                    style={ scheduleModel?.type === ScheduleType.ASAP ? { border: `2px solid ${ settings!.design.colors.secondary.background }` } : {} }
                  >
                    <ClockIcon style={ iconStyle } />
                    <ScheduleOptionText>As soon as possible</ScheduleOptionText>
                  </ScheduleOptionContainer>
                </ScheduleStyledContainer>
              )
              : (
                <>
                  <ScheduleStyledCalendar
                    calendarType="US"
                    maxDetail="month"
                    minDetail="month"
                    minDate={ minDate }
                    onClickDay={ onDateSelected }
                    next2Label={ null }
                    prev2Label={ null }
                    tileDisabled={ isDateDisabled }
                    value={ scheduleModel?.date }
                  />
                  {
                    selectedDateString
                  && serviceDateScheduleSettings?.days[ selectedDateString ]
                  && (
                    <ScheduleTimesContainer ref={ timeSlotsContainerRef }>
                      {
                        scheduleModel!.date && (
                          <ScheduleTimesDateContainer>
                            <ScheduleTimesDateText>{ dateFormatter( scheduleModel!.date ) }</ScheduleTimesDateText>
                            {
                              isWindowServiceType( selectedServiceType! ) && settings.schedule.booking_window_service_text && (
                                <ScheduleWindowServiceTextContainer>
                                  <ScheduleWindowServiceText>{ settings.schedule.booking_window_service_text }</ScheduleWindowServiceText>
                                </ScheduleWindowServiceTextContainer>
                              )
                            }
                          </ScheduleTimesDateContainer>
                        )
                      }
                      {
                        isWindowServiceType( selectedServiceType! ) && selectedServiceType.allow_any && (
                          <ScheduleTimes>
                            <ScheduledTimeNoPreferenceRow>
                              <ScheduleTimeSlotsContainer>
                                <ScheduleTimeSlotContainer>
                                  <ScheduleTimeSlot style={ getTimeSlotStyle( null ) } onClick={ () => onNoPreferenceSelected() }> No Preference</ScheduleTimeSlot>
                                </ScheduleTimeSlotContainer>
                              </ScheduleTimeSlotsContainer>
                            </ScheduledTimeNoPreferenceRow>
                          </ScheduleTimes>
                        )
                      }
                      <ScheduleTimes>
                        {
                          serviceDateScheduleSettings.days[ selectedDateString ].map( ( serviceDateScheduleSetting ) => (
                            <ScheduleTimesRow key={ serviceDateScheduleSetting.id || serviceDateScheduleSetting.label } style={ isBasicServiceType( selectedServiceType! ) ? { flex: "1 0 33%" } : { flex: "0 0 33%" } }>
                              {
                                isBasicServiceType( selectedServiceType! ) && (
                                  <ScheduleTimesRowHeader>{ serviceDateScheduleSetting.label }</ScheduleTimesRowHeader>
                                )
                              }
                              <ScheduleTimeSlotsContainer>
                                {
                                  serviceDateScheduleSetting.time_slots.map( ( timeSlot ) => (
                                    <ScheduleTimeSlotContainer key={ timeSlot.start }>
                                      <ScheduleTimeSlot style={ getTimeSlotStyle( timeSlot.start ) } onClick={ () => onTimeSelected( timeSlot, serviceDateScheduleSetting.id ) }>
                                        { timeSlot.label }
                                      </ScheduleTimeSlot>
                                    </ScheduleTimeSlotContainer>
                                  ) )
                                }
                              </ScheduleTimeSlotsContainer>
                            </ScheduleTimesRow>
                          ) )
                        }
                      </ScheduleTimes>
                    </ScheduleTimesContainer>
                  )
                  }
                </>
              )
          }
        </BookingStepPageMainContentContainer>
      </BookingStepPageMainContainer>
      <BookingFooter buttonText="Next" disabled={ isNextButtonDisabled } onButtonClick={ next } />
    </BookingStepPageContainer>
  )
}
