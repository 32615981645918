import React from "react"
import styled from "styled-components"

import { ErrorIcon } from "../../../../components/Icon"
import { PageContainer } from "../../../../components/Page"
import { ERROR_COLOR } from "../../../../helpers/styles"
import { usePaymentState } from "../../components/PaymentContext"
import {
  PaymentPageContactDescription,
  PaymentPageDescription,
  PaymentPageHeadline,
  PaymentPagePayeeName,
  PaymentPageTitle,
} from "../../components/PaymentPage"

const StyledTitle = styled( PaymentPageTitle )`
  color: ${ ERROR_COLOR };
`

export const ExpiredPage: React.FunctionComponent = () => {
  const { paymentSummary } = usePaymentState()

  if( ! paymentSummary ) {
    return null
  }

  return (
    <PageContainer showPoweredBy={ true }>
      <PaymentPageHeadline>Error!</PaymentPageHeadline>
      <PaymentPageDescription>Your payment link for <PaymentPagePayeeName>{ paymentSummary.name }</PaymentPagePayeeName> has expired.</PaymentPageDescription>
      <ErrorIcon/>
      <StyledTitle>Payment Link Expired</StyledTitle>
      <PaymentPageContactDescription>Please contact { paymentSummary.name } for any questions</PaymentPageContactDescription>
    </PageContainer>
  )
}
