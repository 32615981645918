import React, { createContext, useContext, useState } from "react"

import type { LocalVisits } from "ol-shared"

import type {
  AssigneeOption,
  BasicServiceType,
  BookingAssigneeModel,
  BookingSettings,
  BookingStep,
  CalendarEvent,
  ScheduleStep,
  ScheduleType,
  ServiceType,
  WindowServiceType } from "../types"
import {
  BookingServiceType,
} from "../types"

interface BookingContextType {
  assigneeOptions: AssigneeOption[]
  calendarEvent: CalendarEvent | null
  errorMessage: string | null
  isPreview: boolean | null
  model: BookingContextModel
  scheduleStep: ScheduleStep | null
  settings: BookingSettings | null
  step: BookingStep | null

  setAssigneeOptions( assigneeOptions: AssigneeOption[] ): void
  setCalendarEvent( calendarEvent: CalendarEvent | null ): void
  setErrorMessage( errorMessage: string | null ): void
  setIsPreview( isPreview: boolean | null ): void
  setModel( model: BookingContextModel ): void
  setScheduleStep( scheduleStep: ScheduleStep | null ): void
  setSettings( settings: BookingSettings | null ): void
  setStep( step: BookingStep ): void
}

export interface BookingContextModel {
  answers: LocalVisits.Types.Booking.Answers
  assignee?: BookingAssigneeModel
  location?: LocalVisits.Types.MerchantSettings.BookingLocation
  merchant_id?: string
  schedule?: {
    date?: Date
    end?: string | null
    start?: string | null
    time_slot_id?: string | null
    type: ScheduleType
  }
  service?: {
    group_id: string
    type_id: string
  }
  utm_source?: string
}

export const BookingContext = createContext<BookingContextType>( null! )

// eslint-disable-next-line @typescript-eslint/ban-types
export const BookingContextProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = ( props ) => {
  const [ calendarEvent, setCalendarEvent ] = useState<CalendarEvent | null>( null )
  const [ errorMessage, setErrorMessage ] = useState<string | null>( null )
  const [ isPreview, setIsPreview ] = useState<boolean | null>( null )
  const [ model, setModel ] = useState<BookingContextModel>( { answers: {} } )
  const [ scheduleStep, setScheduleStep ] = useState<ScheduleStep | null>( null )
  const [ settings, setSettings ] = useState<BookingSettings | null>( null )
  const [ step, setStep ] = useState<BookingStep | null>( null )
  const [ assigneeOptions, setAssigneeOptions ] = useState<AssigneeOption[]>( [] )

  const contextValue: BookingContextType = {
    assigneeOptions,
    calendarEvent,
    errorMessage,
    isPreview,
    model,
    scheduleStep,
    settings,
    step,
    setAssigneeOptions,
    setCalendarEvent,
    setErrorMessage,
    setIsPreview,
    setModel,
    setScheduleStep,
    setSettings,
    setStep,
  }

  return <BookingContext.Provider value={ { ...contextValue } }>{ props.children }</BookingContext.Provider>
}

export function isBasicServiceType( serviceType: ServiceType ): serviceType is BasicServiceType {
  return serviceType.type === BookingServiceType.BASIC
}

export function isWindowServiceType( serviceType: ServiceType ): serviceType is WindowServiceType {
  return serviceType.type === BookingServiceType.WINDOW
}

export function useBookingState(): BookingContextType {
  const context = useContext( BookingContext )
  if( ! context ) {
    throw new Error( "useBookingState must be used within the BookingContextProvider" )
  }
  return context
}
